@import '../../assets/styles/mixins/respond-to.scss';

.JobListItem {
    background-color: #fff;
}

.JobListItem__hint {
    font-style: italic;
    padding-right: 0.1em;
}

.JobListItem__informations,
.JobListItem__actions {
    display: flex;
    align-items: center;

    min-height: 70px;
}

.JobListItem__informations {
    overflow: hidden;
}

.JobListItem__state,
.JobListItem__details,
.JobListItem__action {
    padding: 10px;
}

.JobListItem__details,
.JobListItem__action {
    flex-grow: 1;
    flex-shrink: 1;
}

.JobListItem__state,
.JobListItem__action {
    text-align: center;
}

.JobListItem__state,
.JobListItem__action--fix-width {
    flex-grow: 0;
    flex-shrink: 0;

    width: 70px;
}

.JobListItem__state,
.JobListItem__details-primary,
.JobListItem__details-secondary {
    position: relative;
}

.JobListItem__details {
    overflow: hidden;
}

.JobListItem__details-primary {
    display: flex;
}

.JobListItem__ad-type {
    font-weight: 600;
}

.JobListItem__details-primary-icon-link {
    display: none;
}

.JobListItem__details-primary-icon {
    vertical-align: middle;
}

.JobListItem__action-link {
    display: block;
}

.JobListItem--skeleton {
    .JobListItem__state:before,
    .JobListItem__details-primary:before,
    .JobListItem__details-secondary:before,
    .JobListItem__action:before {
        content: '\00a0';

        display: inline-block;

        background-color: #cccccc;
        border-radius: 250em;
    }

    // Keep height of element
    .JobListItem__state:after,
    .JobListItem__details-primary:after,
    .JobListItem__details-secondary:after {
        content: '\00a0';

        display: inline-block;

        color: transparent;
    }

    .JobListItem__state:before,
    .JobListItem__details-primary:before,
    .JobListItem__details-secondary:before {
        position: absolute;

        top: 50%;
        height: 0.8em;
        margin-top: -0.4em;
    }

    // Definition of sizes
    .JobListItem__state:before {
        left: 50%;

        width: 40px;
        margin-left: -20px;
    }

    .JobListItem__details-primary:before {
        width: 70%;
        max-width: 420px;
    }

    .JobListItem__details-secondary:before {
        width: 50%;
        max-width: 200px;
    }

    .JobListItem__action:before {
        height: 24px;
        width: 24px;

        border-radius: 50%;
    }
}

@include respond-to('sm') {
    .JobListItem {
        display: flex;
        align-items: stretch;

        height: 86px;
    }

    .JobListItem__informations {
        flex-grow: 1;
    }

    .JobListItem__state {
        width: 80px;
    }

    .JobListItem__details-primary-icon-link {
        display: inline;

        margin-left: 0.35em;
    }

    .JobListItem__actions {
        flex-grow: 0;
        flex-shrink: 0;

        width: 300px;
    }

    .JobListItem__action {
        flex-grow: 1;
        flex-shrink: 1;
    }

    .JobListItem__action--details {
        display: none;
    }
}
