.UnlockCandidateView__content {
    flex-shrink: 0;

    display: flex;

    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.UnlockCandidateView__description {
    margin-bottom: 0;
    padding-left: 24px;
}

.UnlockCandidateView__checkbox {
    margin-top: 4px;
}

.UnlockCandidateView__checkbox-label {
    font-weight: normal;
    cursor: pointer;
}

// Mobile specific style and structure
.UnlockCandidateView--mobile {
    flex-shrink: 1;

    display: flex;
    flex-direction: column;

    overflow-y: auto;

    .UnlockCandidateView__content {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .UnlockCandidateView__description-line {
        font-size: 18px;
    }

    .UnlockCandidateView__description-line + .UnlockCandidateView__description-line {
        margin-top: 16px;
    }

    .UnlockCandidateView__checkbox {
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }
}

// Desktop specific style and structure
.UnlockCandidateView--desktop {
    .UnlockCandidateView__header {
        text-align: center;
    }

    .UnlockCandidateView__content {
        padding-bottom: 16px;
    }

    .UnlockCandidateView__description-line + .UnlockCandidateView__description-line {
        margin-top: 8px;
    }

    .UnlockCandidateView__footer {
        background-color: #ffffff;

        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}
