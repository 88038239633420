.ForwardCandidateView__form {
    display: flex;
    flex-direction: column;
}

.ForwardCandidateView__modal-header {
    text-align: center;
}

.ForwardCandidateView__sub-header {
    font-size: 15px;
    font-weight: bold;
}

.ForwardCandidateView__modal-footer {
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ForwardCandidateView__overlay-container {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;

    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
}
