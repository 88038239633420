@import '../../assets/styles/config/colors';

.CandidateStateChangeViewMobile__form {
    display: flex;

    flex-direction: column;
}

.CandidateStateChangeViewMobile__content {
    padding-top: 26px;

    flex-grow: 1;
    flex-shrink: 1;

    overflow-y: auto;
}

.CandidateStateChangeViewMobile__candidate {
    padding: 0 16px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.CandidateStateChangeViewMobile__state {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 16px;
    width: 100%;
    font-weight: normal;
    text-align: left;
    font-size: 16px;
    line-height: 15px;
    border: 0;
    background: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.CandidateStateChangeViewMobile__state-radio:focus + .CandidateStateChangeViewMobile__state {
    background-color: #f4f4f4;
}

.CandidateStateChangeViewMobile__state-radio {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

.CandidateStateChangeViewMobile__state-indicator {
    position: relative;
    margin: 16px;
    width: 13px;
    height: 13px;
    background-color: #fff;
    border-radius: 50%;
    border: solid 1px #4c4c4c;
    flex-shrink: 0;
}

.CandidateStateChangeViewMobile__state-indicator:before {
    content: '';
    position: absolute;
    display: inline-block;
    margin: -17px 0 0 5px;
    width: 1px;
    height: 16px;
    background-color: #c9c9c9;
}

.CandidateStateChangeViewMobile__state-indicator:after {
    content: '';
    position: absolute;
    display: inline-block;
    margin: 12px 0 0 5px;
    width: 1px;
    height: 16px;
    background-color: #c9c9c9;
}

.CandidateStateChangeViewMobile__state-name {
    margin-top: -1px;
    flex-grow: 1;
    flex-shrink: 1;
}


.CandidateStateChangeViewMobile__state-container:first-of-type {
    .CandidateStateChangeViewMobile__state-indicator:before {
        margin: 12px 0 0 5px;
        height: 16px;
    }
}

.CandidateStateChangeViewMobile__state-container:last-of-type {
    .CandidateStateChangeViewMobile__state-indicator:after {
        display: none;
    }
}

.CandidateStateChangeViewMobile__state--hired.CandidateStateChangeViewMobile__state--current {
    .CandidateStateChangeViewMobile__state-indicator:before {
        display: none;
    }

    .CandidateStateChangeViewMobile__state-indicator:after {
        display: none;
    }
}

.CandidateStateChangeViewMobile__state-radio:checked + .CandidateStateChangeViewMobile__state {
    font-weight: 600;
}

.CandidateStateChangeViewMobile__state--current,
.CandidateStateChangeViewMobile__state-radio:checked + .CandidateStateChangeViewMobile__state {

    &.CandidateStateChangeViewMobile__state--interesting {
        .CandidateStateChangeViewMobile__state-indicator {
            background-color: $roman;
            border-color: $roman;
        }
    }

    &.CandidateStateChangeViewMobile__state--phone-interview {
        .CandidateStateChangeViewMobile__state-indicator {
            background-color: $malibu;
            border-color: $malibu;
        }
    }

    &.CandidateStateChangeViewMobile__state--interview {
        .CandidateStateChangeViewMobile__state-indicator {
            background-color: $sea-nymph;
            border-color: $sea-nymph;
        }
    }

    &.CandidateStateChangeViewMobile__state--hired {
        .CandidateStateChangeViewMobile__state-indicator {
            background-color: $casablanca;
            border-color: $casablanca;
        }
    }
}
