.SignupFormEmailInUse {
    padding-bottom: 60px;
}

.SignupFormEmailInUse__action-container {
    margin-bottom: 40px;
}

.SignupFormEmailInUse__paragraph {
    margin-top: 10px;
}
