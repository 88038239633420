@import '~@truffls/design-system/scss/settings/colors';

.JobFormTasksListItemPlaceholder {
    display: flex;
    align-items: center;

    min-height: 60px;

    padding: 16px;

    background: #f2f2f2;
    border: 1px dashed $color-shades-450;

    font-style: italic;
    color: $color-shades-450;
}
