@import '~@truffls/design-system/scss/tools/reset-button';
@import '~@truffls/design-system/scss/settings/colors';

@import '../../assets/styles/mixins/respond-to';

$icon-size: 40px;

.DocumentItem {
    display: flex;
    justify-content: row;
    align-items: center;

    width: 100%;

    word-wrap: break-word; // fallback for non supporting browsers
    overflow-wrap: break-word;

    padding: 8px 16px 8px 8px;

    transform: background-color 150ms ease-in-out;

    &:hover {
        background: $color-shades-30;
    }

    &--highlight {
        .DocumentItem__name {
            font-weight: bold;
        }
    }
}

.DocumentItem__icon {
    display: none; // don't show in mobile

    width: $icon-size;
    height: $icon-size;

    flex-shrink: 0;

    margin-right: 12px;
}

.DocumentItem__info {
    flex: 1;

    min-width: 1%; // flex items need a min-width for word-wrap to be applied

    margin-right: 16px;
}

.DocumentItem__info__name,
.DocumentItem__info__meta {
    display: block;
    line-height: 18px;
}

.DocumentItem__info__name {
    color: $color-black;

    margin-bottom: 2px;
}

.DocumentItem__info__meta {
    color: $color-shades-450;
}

.DocumentItem__download-button {
    @include reset-button;
}

@include respond-to('sm') {
    .DocumentItem__icon {
        display: block;
    }
}
