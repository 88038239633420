@import '~@truffls/design-system/scss/settings/_colors';
@import '../../assets/styles/mixins/respond-to';

.RequestDocumentsCTA {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 12px 16px;

    background-color: $color-shades-30;

    border-top: 1px solid $color-shades-200;
    border-bottom: 1px solid $color-shades-200;
}

.RequestDocumentsCTA__text {
    color: $color-shades-450;
    font-size: 12px;
    font-style: italic;
    line-height: 1.33;
    letter-spacing: 0.2px;

    margin-bottom: 12px;
}

.RequestDocumentsCTA__button {
    padding: 6px 8px;
    font-size: 10px;
}

@include respond-to('sm') {
    .RequestDocumentsCTA {
        flex-direction: row;
        justify-content: space-between;
    }

    .RequestDocumentsCTA__text {
        margin-bottom: 0;
    }
}
