@import '../../assets/styles/mixins/respond-to.scss';
@import '../NextNewCandidateBanner/NextNewCandidateBanner.variables';

.CandidateDetailsPage__container {
    padding: 16px;
}

.CandidateDetailsPage__title {
    display: none;
}

.CandidateDetailsPage__columns {
    display: flex;
    flex-direction: column;
}

.CandidateDetailsPage__item {
    height: 48px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CandidateDetailsPage__summary {
    display: flex;
    align-items: center;

    height: 70px;
}

.CandidateDetailsPage__details {
    flex-grow: 1;
    flex-shrink: 1;

    padding-top: 10px;

    height: 100%;

    overflow: hidden;
}

.CandidateDetailsPage__state-picker {
    text-align: center;
}

.CandidateDetailsPage__action-container {
    padding: 6px 0;
}

.CandidateDetailsPage__action {
    width: 100%;
}

.CandidateDetailsPage__rating {
    margin-top: 12px;
}

@include respond-to('sm') {
    .CandidateDetailsPage__details {
        padding-top: 5px;
    }

    .CandidateDetailsPage__state-picker {
        margin-bottom: 20px;
        padding-right: 10px;
    }

    .CandidateDetailsPage__actions-and-rating {
        display: flex;
        justify-content: space-between;

        padding-top: 3px;

        > * {
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    .CandidateDetailsPage__actions {
        display: flex;
        justify-content: space-between;
        order: 2;

        width: auto;

        margin-right: -4px;
        margin-left: -4px;
    }

    .CandidateDetailsPage__action-container {
        padding-left: 4px;
        padding-right: 4px;
    }

    .CandidateDetailsPage__rating {
        order: 1;
        margin-top: 9px;
    }

    .CandidateDetailsPage__actions-and-rating--multiple-actions {
        flex-direction: column;
    }

    .CandidateDetailsPage__actions-and-rating--multiple-actions .CandidateDetailsPage__actions {
        order: 1;
    }

    .CandidateDetailsPage__actions-and-rating--multiple-actions .CandidateDetailsPage__rating {
        order: 2;
    }
}

@include respond-to('md') {
    .CandidateDetailsPage {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .CandidateDetailsPage__next-new-candidate-banner + .CandidateDetailsPage__container {
        height: calc(100% - #{$new-candidate-banner-height});
    }

    .CandidateDetailsPage__container {
        display: flex;
        flex-direction: column;
        padding: 0;
        height: 100%;
    }

    .CandidateDetailsPage__title {
        flex-grow: 0;
        flex-shrink: 0;

        display: block;
    }

    .CandidateDetailsPage__columns {
        flex-grow: 1;
        flex-shrink: 1;

        display: flex;
        flex-direction: row;

        // The flex item shouldn't take up more than the available height
        height: 0;
    }

    .CandidateDetailsPage__column {
        flex-basis: 50%;
        flex-grow: 1;

        display: flex;
        flex-direction: column;

        min-width: 1%; // flex items need a min-width for behaving like blocks
        padding-bottom: 0;
    }

    .CandidateDetailsPage__column--left {
        overflow-y: auto;
    }

    .CandidateDetailsPage__column--right {
        display: flex;
        flex-direction: column;
    }

    .CandidateDetailsPage__tabs {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .CandidateDetailsPage__column-content {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;

        overflow-y: auto;
        overflow-x: hidden;

        > * {
            flex-grow: 1;
        }
    }

    .CandidateDetailsPage__column--right .CandidateDetailsPage__column-content {
        padding-left: 10px;
    }

    .CandidateDetailsPage__actions-and-rating {
        padding-right: 10px;
    }
}
