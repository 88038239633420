.JobListItemTitle {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &,
    &:hover,
    &:focus {
        color: inherit;
    }
}
