@import '~@truffls/design-system/scss/settings/_colors';

.OverlayUserMenu__nav-logout {
    margin-top: 7px;
}

.OverlayUserMenu__nav-logout__name {
    display: block;
    font-size: 20px;
    margin-bottom: -12px;
}

.OverlayUserMenu__nav-logout__subname {
    font-size: 16px;
}
