.DeleteCandidateViewDesktop__header {
    text-align: center;
}

.DeleteCandidateViewDesktop__title {
    font-weight: 600;
}

.DeleteCandidateViewDesktop__body {
    display: flex;
    position: relative;
    padding: 0;
}

.DeleteCandidateViewDesktop__app-preview {
    margin: 0;
    width: 50%;
    height: 300px;
    overflow: hidden;
    background-image: url('../../../assets/images/notification_preview_background.png');
}

.DeleteCandidateViewDesktop__app-preview__title {
    padding: 16px;
    color: #fff;
    font-size: 15px;
    text-align: center;
}

.DeleteCandidateViewDesktop__app-preview__phone {
    margin: 0 auto;
    width: 230px;
}

.DeleteCandidateViewDesktop__explanation {
    padding: 10px;
    width: 50%;
}

.DeleteCandidateViewDesktop__footer {
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
