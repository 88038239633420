@import "../../../assets/styles/config";

.CandidateResumeSection {
    padding-bottom: 20px;
    margin-bottom: 20px;

    border-bottom: 1px solid $gray-lighter;

    h1 {
        margin-top: 0;
        margin-bottom: 15px;

        font-size: $font-size-h4;
    }

    ul {
        display: block;

        margin: 0;
        padding: 0;

        list-style: none;
    }
}
