@import "../../assets/styles/mixins/respond-to";
@import "./NextNewCandidateBanner.variables";

.NextNewCandidateBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $new-candidate-banner-height;
    background-color: #f5f5f5;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f2f2f2;

        .NextNewCandidateBanner__badge,
        .NextNewCandidateBanner__badge-icon {
            opacity: 0.9;
        }

        .NextNewCandidateBanner__text-link {
            text-decoration: underline;
        }
    }
}

.NextNewCandidateBanner__badge,
.NextNewCandidateBanner__badge-icon {
    margin-right: 10px;
    opacity: 1;
    transition: opacity 0.2s;
}

.NextNewCandidateBanner__badge {
    display: inline-block;
    margin-top: -3px;
}

.NextNewCandidateBanner__badge-icon {
    display: none;
    margin-top: 3px;
}

.NextNewCandidateBanner__text-link {
    color: #4c4c4c;
    font-size: 14px;
    transition: text-decoration 0.2s;

    &:visited {
        color: inherit;
    }
}

@include respond-to("sm") {
    .NextNewCandidateBanner__badge {
        display: none;
    }

    .NextNewCandidateBanner__badge-icon {
        display: inline-block;
    }

    .NextNewCandidateBanner__text-link {
        font-size: 16px;
    }
}
