.SlideUpTransition--enter-active,
.SlideUpTransition--exit-active {
    transition-property: transform;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.SlideUpTransition--enter {
    transform: translateY(100%);
}

.SlideUpTransition--enter-active {
    transform: translateY(0);
}

.SlideUpTransition--exit {
    transform: translateY(0);
}

.SlideUpTransition--exit-active {
    transform: translateY(100%);
}
