@import '~@truffls/design-system/scss/settings/_colors';

.TemplatesDropdownItem {
    display: flex;
    align-items: center;
}

.TemplatesDropdownItem__flag {
    width: 12px;
    height: 12px;

    margin-right: 6px;
}

.TemplatesDropdownItem__label {
    font-size: 12px;
    line-height: 16px;
    color: $color-shades-700;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
