@import '../../../assets/styles/config';
@import '~@truffls/design-system/scss/settings/colors';
@import '~@truffls/design-system/scss/components/button/settings';
@import '../../../assets/styles/mixins/respond-to';

.SignupPageNavigation__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    z-index: 1000;

    pointer-events: none;
}

.SignupPageNavigation__spacer {
    pointer-events: none;
}

.SignupPageNavigation__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 64px;

    padding: 13px 8px;

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: $color-truffls-green-500;

    color: $color-white;

    pointer-events: all;

    &:before,
    &:after {
        display: none;
    }
}

.SignupPageNavigation__logo-container {
    margin-left: 12px;
}

.SignupPageNavigation__logo {
    display: inline-block;

    height: 38px;
}

.SignupPageNavigation__logo--green {
    display: none;
}

.SignupPageNavigation__menu-toggle-container {
    margin: 2px 0;
}

.SignupPageNavigation__menu-toggle {
    min-width: 0;

    padding-left: 8px;
    padding-right: 8px;

    &:hover {
        background-color: rgba($color-shades-200, 0.12);
    }

    &:focus {
        background-color: rgba($color-shades-200, 0.24);
    }

    &:active {
        background-color: rgba($color-shades-200, 0.32);
    }
}

.SignupPageNavigation__menu-toggle-icon {
    display: block;

    height: 20px;
    width: 20px;

    fill: currentColor;
}

.SignupPageNavigation__menu {
    display: none;
    flex-shrink: 0;

    margin: 2px 0;
    padding: 0;

    list-style: none;
}

.SignupPageNavigation__menu-item {
    display: inline-block;
}

@include respond-to('md') {
    .SignupPageNavigation__content {
        height: 86px;

        padding: 24px 24px;

        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: none;

        color: $color-black;
    }

    .SignupPageNavigation__logo-container {
        display: block;
        margin-left: 0;
    }

    .SignupPageNavigation__logo--white {
        display: none;
    }

    .SignupPageNavigation__logo--green {
        display: inline-block;
    }

    .SignupPageNavigation__menu-toggle-container {
        display: none;
    }

    .SignupPageNavigation__menu {
        display: flex;

        margin-left: -8px;
        margin-right: -8px;
    }

    .SignupPageNavigation__menu-item {
        margin-left: 8px;
        margin-right: 8px;
    }
}
