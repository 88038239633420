@import '~@truffls/design-system/scss/settings/_colors';

.SearchBarResultsListItem {
    display: flex;
    flex-direction: row;

    padding: 8px;
}

.SearchBarResultsListItem__avatar-container {
    flex-shrink: 0;
    flex-grow: 0;

    height: 40px;
    width: 40px;

    margin-right: 12px;
}

.SearchBarResultsListItem__image-container {
    flex-shrink: 0;
    flex-grow: 0;

    height: 40px;
    width: 40px;

    margin-right: 12px;

    // border-radius: 50%;

    // background-color: $color-shades-200;
    // We inline a transparent image allow us to apply a transition on the background image.
    // background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;

    // transition: background-image 150ms linear;
}

.SearchBarResultsListItem__image {
    display: block;

    width: 100%;
    height: 100%;

    opacity: 0;
}

.SearchBarResultsListItem__content {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;

    min-width: 0;

    padding: 2px 0;
}

.SearchBarResultsListItem__title,
.SearchBarResultsListItem__subtitle {
    font-size: 14px;
    line-height: 18px;

    > strong {
        font-weight: 600;
    }
}

.SearchBarResultsListItem__title {
    color: $color-shades-900;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.SearchBarResultsListItem__subtitle {
    max-height: 36px;
    overflow: hidden;

    color: $color-shades-450;

    > strong {
        color: $color-shades-900;
    }
}

.SearchBarResultsListItem--highlighted {
    background-color: $color-shades-200;
}
