@import '../../assets/styles/config/colors';
@import '../../assets/styles/mixins/respond-to';

.CandidateDetails {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 100%;
}

.CandidateDetails__avatar {
    flex-shrink: 0;

    height: 50px;
    width: 50px;

    margin-right: 10px;
}

.CandidateDetails__details-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.CandidateDetails__primary {
    display: flex;
}

.CandidateDetails__secondary,
.CandidateDetails__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.CandidateDetails__name {
    flex-shrink: 1;
    min-width: 0;
}

.CandidateDetails__forward-button {
    appearance: none;
    display: inline-block;
    margin: 0 0 -2px 6px;
    padding: 0;
    line-height: 20px;
    background-color: #fff;
    border: none;
    cursor: pointer;
}

.CandidateDetails__forward-button-icon {
    height: 22px;
}

.CandidateDetails__occupation {
    font-weight: 600;
}

.CandidateDetails__expiration-label {
    display: inline-block;
    padding: 1px 10px;
    background-color: #cdcdcd;
    font-size: 12px;
    border-radius: 2px;
}

.CandidateDetails__state-rating-container {
    display: flex;
    align-items: center;
}

.CandidateDetails__state {
    display: inline-block;
    font-weight: 600;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.CandidateDetails__state-dot {
    display: inline-block;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #4c4c4c;
}

.CandidateDetails__state-dot--new,
.CandidateDetails__state-dot--locked {
    background-color: $black-marlin;
}
.CandidateDetails__state-dot--interesting {
    background-color: $roman;
}
.CandidateDetails__state-dot--phone-interview {
    background-color: $malibu;
}
.CandidateDetails__state-dot--interview {
    background-color: $sea-nymph;
}
.CandidateDetails__state-dot--hired {
    background-color: $casablanca;
}

.CandidateDetails--skeleton {
    .CandidateDetails__details-info {
        padding: 5px 0;
    }
}

.CandidateDetails__state--skeleton {
    width: 25%;
    max-width: 100px;
    height: 10px;
    margin-top: 3px;
    background-color: #ccc;
    border-radius: 250em;
}

.CandidateDetails--faded {
    .CandidateDetails__avatar,
    .CandidateDetails__primary,
    .CandidateDetails__state-rating-container {
        opacity: 0.5;
    }
}

@include respond-to('sm') {
    .CandidateDetails__avatar {
        height: 60px;
        width: 60px;
    }

    .CandidateDetails__forward-button {
        margin-top: 2px;
        height: 26px;
    }

    .CandidateDetails__forward-button-icon {
        margin: 0;
        height: 26px;
    }

    .CandidateDetails__state {
        display: none;
    }

    .CandidateDetails__state--skeleton {
        display: none;
    }
}
