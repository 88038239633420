.JobFormAvailabilityFormFields__fields {
    display: flex;
    flex-direction: row;
}

.JobFormAvailabilityFormFields__field-start,
.JobFormAvailabilityFormFields__field-date {
    flex-grow: 1;
    flex-shrink: 1;
}

.JobFormAvailabilityFormFields__field-start {
    max-width: 276px;
}

.JobFormAvailabilityFormFields__field-date {
    max-width: 200px;

    margin-left: 12px;
}
