@use '~@truffls/design-system/scss/settings/_colors' as colors;
@use '../Navigation/Navigation.settings.scss' as navigation;

.root {
    position: fixed;
    top: navigation.$navigation-bar-height + 15px;
    width: 100%;
    z-index: 150;
}

.item {
    max-width: 500px;

    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;

    border-left-width: 0;
    border-bottom-width: 0;
    border-radius: 4px;

    box-shadow: 0 5px 20px 0 rgba(colors.$color-shades-600, 0.6);

    pointer-events: all;

    text-align: center;
}

.button {
    padding: 0;
    border: none;
}
