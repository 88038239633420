@import '../../assets/styles/mixins/respond-to';

.CandidateStatePicker__desktop {
    display: none;
}

.CandidateStatePicker__mobile-unlock-button-container {
    padding: 6px 0;
}

.CandidateStatePicker__mobile-unlock-button {
    width: 100%;
}

@include respond-to('sm') {
    .CandidateStatePicker__desktop {
        display: block;
    }

    .CandidateStatePicker__mobile {
        display: none;
    }
}
