.CheckoutAddressForm__fieldset + .CheckoutAddressForm__fieldset {
    margin-top: 16px;
}

.CheckoutAddressForm__form-row {
    margin-left: -6px;
    margin-right: -6px;
}

.CheckoutAddressForm__form-column {
    padding-left: 6px;
    padding-right: 6px;
}
