.DialogPageContainer {
    padding: 30px 10px;
}

.DialogPageContainer__dialog {
    max-width: 540px;

    margin: 0 auto;
}

/* TODO: Use variable or mixin for mediaQuery in stylesheet of DialogPageContainer component */
@media (min-width: 768px) {
    .DialogPageContainer {
        padding-top: 140px;
    }
}
