@import '~@truffls/design-system/scss/tools/reset-button';

.ListItem {
    @include reset-button();

    display: flex;
    align-items: center;

    width: 100%;
    height: 32px;

    padding: 4px 16px;

    outline: 0;

    font: inherit;
    line-height: 24px;
    text-align: left;

    cursor: pointer;
}

.ListItem:hover,
.ListItem:focus,
.ListItem--highlight {
    background-color: rgba(66, 76, 87, 0.13);
}
