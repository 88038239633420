.TutorialMobileContent {
    position: relative;

    padding-bottom: 20px;
}

.TutorialMobileContent__decoration-line {
    margin-bottom: 24px;
}

.TutorialMobileContent__text {
    position: relative;
    margin-top: 24px;
}

.TutorialMobileContent__actions {
    margin-top: 24px;

    > * + * {
        margin-left: 8px;
    }
}
