@import '~@truffls/design-system/scss/settings/_colors.scss';
@import '../../assets/styles/config';

.footer {
    padding-top: 15px;
    padding-bottom: 10px;

    border-top: 1px solid $color-shades-100;
    background: $color-white;

    color: $text-color;
}

.footer__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin: 0 -5px;
    padding: 0;
}

.footer__list-item {
    display: block;

    list-style: none;

    margin: 0 5px;
}

.footer__link {
    &,
    &:hover,
    &:focus {
        color: inherit;
    }
}
