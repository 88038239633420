@import '~@truffls/design-system/scss/settings/colors';

.JobFormTasksListItemFormField {
    border-top: 1px solid transparent;
    border-bottom: 1px solid $color-shades-200;
    background: white;
    padding: 9px 24px 9px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-end;

    svg {
        fill: $color-shades-450;
    }
}

.JobFormTasksListItemFormField__drag-handle {
    margin: 0 10px 0 -4px;
    display: flex;
}

.JobFormTasksListItemFormField__drag-handle--inactive {
    .JobFormTasksListItemFormField__drag-handle-icon {
        fill: $color-shades-100;
    }
}

.JobFormTasksListItemFormField__content {
    flex-grow: 1;
    flex-shrink: 1;

    min-height: 40px;
    display: flex;
    align-items: center;
    // max-width: calc(100% - 103px);
}

.JobFormTasksListItemFormField__display {
    padding: 4px 11px;

    border: 1px solid transparent;

    white-space: pre-line;
    letter-spacing: 0.25px;
}

.JobFormTasksListItemFormField__input {
    resize: none;
}

.JobFormTasksListItemFormField__actions {
    flex-shrink: 0;
}

.JobFormTasksListItemFormField__action {
    appearance: none;

    width: 24px;
    height: 24px;

    margin-left: 12px;
    padding: 2px;

    background: none;
    border: none;
    outline: none;

    text-align: center;
    align-items: center;
}

.JobFormTasksListItemFormField__action-icon {
    max-width: 100%;
    max-height: 100%;
}

.JobFormTasksListItemFormField__action--save .JobFormTasksListItemFormField__action-icon {
    fill: $color-truffls-green-500;
}
