@import '~@truffls/design-system/scss/settings/colors';

.SignupSuccessTrialCountdown {
    position: relative;

    margin-top: 16px;
    margin-right: 16px;
    padding: 16px 20px;

    background-color: $color-white;
    border: 4px solid $color-shades-900;
    border-radius: 10px;
    box-shadow: 30px 30px 30px 0 rgba(0, 0, 0, 0.08);
}

.SignupSuccessTrialCountdown__icon-container {
    height: 48px;
    width: 48px;

    padding: 8px;

    position: absolute;
    top: -16px;
    right: -16px;

    background-color: $color-truffls-green-500;
    border-radius: 12px;
}

.SignupSuccessTrialCountdown__icon {
    height: 32px;
    width: 32px;

    fill: $color-white;
}

.SignupSuccessTrialCountdown__title {
    margin-bottom: 4px;

    font-size: 24px;
    font-weight: 600;
    font-style: italic;
    color: $color-truffls-green-500;
}

.SignupSuccessTrialCountdown__content {
    margin-bottom: 0;
}
