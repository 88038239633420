@import '../../assets/styles/mixins/_respond-to';

.CandidateDetailsHeader__forward-button {
    appearance: none;

    display: inline-block;

    margin: 0 0 -2px 6px;
    padding: 0;

    line-height: 20px;

    background-color: #fff;

    border: none;

    cursor: pointer;
}

.CandidateDetailsHeader__forward-button-icon {
    height: 22px;
}

@include respond-to('sm') {
    .CandidateDetailsHeader__forward-button {
        margin-top: 2px;

        height: 26px;
    }

    .CandidateDetailsHeader__forward-button-icon {
        margin: 0;

        height: 26px;
    }
}
