@import "../../assets/styles/mixins";

.JobListPageSection:last-child {
    margin-bottom: 45px;
}

.JobListPageSection__header {
    display: flex;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
}

.JobListPageSection__title {
    flex-grow: 1;
    flex-shrink: 1;

    margin-top: 0;
    margin-bottom: 0;

    font-size: 16px;
    line-height: 32px;
}

.JobListPageSection__list-header {
    flex-grow: 0;
    flex-shrink: 0;

    line-height: 32px;
}

@include respond-to('sm') {
    .JobListPageSection__header {
        display: block;

        margin-top: 20px;
    }

    .JobListPageSection__title {
        margin-bottom: 10px;

        font-size: 24px;
    }

    .JobListPageSection__list-header {
        line-height: inherit;
    }
}
