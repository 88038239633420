.OrderControl {
    display: inline-block;

    padding: 0;
    margin: 0;

    border: 0;
    background: 0;
    -webkit-appearance: none;
    -moz-appearance: none;

    text-align: left;
    white-space: nowrap;
}

.OrderControl__icon {
    display: inline-block;

    height: 0.5em;

    margin-left: 0.35em;

    vertical-align: baseline;
}
