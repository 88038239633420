@use '~@truffls/design-system/scss/tools/reset-button' as *;
@use '~@truffls/design-system/scss/settings/colors' as colors;
@use '../../assets/styles/mixins/respond-to' as *;

.root,
.content {
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    max-width: 640px;

    margin: 0 auto;
    padding: 0 16px;
}

.headline,
.footer {
    text-align: center;
}

.headline {
    flex-grow: 0;
    flex-shrink: 0;

    margin-top: 56px;
    margin-bottom: 28px;

    font-size: 3rem;
    font-weight: 600;
}

.illustration {
    flex-grow: 1;
    flex-shrink: 1;

    display: block;
    width: 240px;
    margin: 0 auto;
}

.paragraph {
    max-width: 350px;

    margin: 0 auto;

    font-size: 24px;
    text-align: center;
    line-height: 1.5;
}

.footer {
    padding: 24px 0 48px;
}

.footerText {
    margin-bottom: 8px;
}

.copyUrlButton {
    @include reset-button;

    color: colors.$color-brand;

    &:hover,
    &:focus {
        color: colors.$color-truffls-green-700;
        text-decoration: underline;
    }

    &:active {
        color: colors.$color-brand;
        text-decoration: none;
    }
}

.goToStartPageLink {
    margin-top: 16px;
}
