@import '~@truffls/design-system/scss/settings/_colors';

.FullScreenMenu {
    width: 100%;
    padding-left: 16px;

    overflow-y: auto;
}

.FullScreenMenu__item {
    display: block;
    width: 100%;
    height: 44px;

    padding: 0;
    text-align: left;
    background-color: transparent;

    border: none;
    border-bottom: solid 1px $color-shades-100;

    color: inherit;
    font-size: 16px;
    line-height: 42px;
    text-decoration: none;

    &,
    &:active,
    &:focus,
    &:hover {
        color: inherit;
        text-decoration: inherit;
    }

    &:last-child {
        border-bottom: none;
    }
}

.FullScreenMenu__separator {
    margin-top: -1px;
    width: 100%;
    height: 1px;
    background-color: $color-shades-700;
}
