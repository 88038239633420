@import '~@truffls/design-system/scss/settings/_colors';

$max-width: 185px;
$icon-size: 16px;
$delete-button-size: 16px;

.Tag {
    display: inline-flex;
    align-items: center;

    max-width: $max-width;

    min-height: 24px;
    padding: 4px;

    background-color: $color-white;

    border: 1px solid $color-shades-900;
    border-radius: 4px;

    &--error {
        border-color: $color-harvard-crimson-400;
    }
}

.Tag__icon {
    flex-shrink: 0;

    width: $icon-size;
    height: $icon-size;

    margin: 0 8px 0 4px;

    > * {
        vertical-align: top;
    }
}

.Tag__label {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;

    word-wrap: break-word; // fallback for non supporting browsers
    overflow-wrap: break-word;
    min-width: 1%; // flex items need a min-width for word-wrap to be applied
}

.Tag__delete-button {
    width: $delete-button-size;
    height: $delete-button-size;

    border: none;

    margin-left: 8px;

    background-image: url('../../assets/images/ic_cancel-circle-filled.svg');
    background-color: transparent;
    background-size: $delete-button-size;
    background-repeat: no-repeat;
    background-position: center;
}
