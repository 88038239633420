@use '~@truffls/design-system/scss/settings/colors' as colors;

.root {
    overflow: hidden;

    color: inherit;
}

.content {
    margin-bottom: 0;
    padding-left: 4px;
    padding-right: 4px;

    h3 {
        margin-top: 0;
        margin-bottom: 8px;

        font-size: 18px;
        color: inherit;
        line-height: 1.33;
    }

    p {
        margin: 0;
        padding: 0;
    }

    ul,
    ol {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 30px;

        list-style-type: none;
    }

    li::before {
        display: inline-block;
        width: 30px;
        margin-left: -30px;
        padding: 0 4px;
        text-align: center;
    }

    ul li::before {
        content: '•';
    }

    ul li[data-emoji]::before {
        content: attr(data-emoji);
        font-size: 0.95em;
    }

    ol {
        counter-set: list-counter;
    }

    ol li::before {
        counter-increment: list-counter;
        content: counter(list-counter) '.';
        padding-right: 8px;

        text-align: right;
        font-variant-numeric: tabular-nums;
    }
}

.root .content p:not(:last-child),
.root .content li:not(:last-child),
.root:not(:last-of-type) .content p,
.root:not(:last-of-type) .content li {
    margin-bottom: 8px;
}
