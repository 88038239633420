.RequestTokenLoginPage {

}

.RequestTokenLoginPage__loading-wheel {
    position: absolute;

    top: 50%;
    left: 50%;

    height: 75px;
    width: 75px;

    margin-top: -37.5px;
    margin-left: -37.5px;
}
