@import "../variables";

.TutorialDesktopContentAnimationWrapper {
    position: absolute;
    bottom: 0;
    right: 0;

    width: 40%;
    height: 60%;
    overflow: visible;
    // final translate values as animation fallback
    transform: translate(-125%, 84%);

    z-index: $z-index-video;
}
.TutorialDesktop--step {
    .TutorialDesktopContentAnimationWrapper {
        // final translate values as animation fallback
        transform: translate(68%, -46%);
    }
}

.TutorialDesktopContent {
    position: relative;

    width: 125%;
    max-width: 300px;

    // final translate values as animation fallback
    transform: translate(0%, -100%);
}
.TutorialDesktop--step {
    .TutorialDesktopContent {
        // final translate values as animation fallback
        transform: translate(-100%, 0%);
    }
}

.TutorialDesktopContent__decoration-line {
    display: none !important;
}

.TutorialDesktopContent__text {
    position: relative;
    margin-top: 32px;
}

.TutorialDesktopContent__actions {
    width: 200%; // ensure actions are always one line
    margin-top: 24px;

    > * + * {
        margin-left: 8px;
    }
}

@media (min-width: 875px) {
    .TutorialDesktopContent__decoration-line {
        display: block !important;
    }
}

@media (min-width: 1040px) {
    .TutorialDesktopContent {
        max-width: 385px;
    }

    .TutorialDesktopContent__actions {
        margin-top: 40px;
    }
}
