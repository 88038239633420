@mixin bs-reset() {
    &[class^='tf-'],
    &[class*=' tf-'] {
        @content;
    }
}

label,
legend {
    @include bs-reset {
        max-width: none;
    }
}

label:not(.tf-form-element__label) {
    @include bs-reset {
        margin-bottom: 0;

        font-weight: inherit;
    }
}

legend {
    @include bs-reset {
        width: auto;
        border: none;
    }
}

// Override the element specific definition of bootstrap
a.tf-button:hover,
a.tf-button:focus {
    text-decoration: none;
}
