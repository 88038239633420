@import '~@truffls/design-system/scss/settings/colors';

.SignupFormStepIndicator {
    padding: 8px 0 0;

    color: $color-brand;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}
