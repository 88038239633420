@import '../../assets/styles/config/colors';
@import '../../assets/styles/mixins/respond-to';

.CandidateStateProgressBar {
    width: 455px;
    height: 100%;
}

.CandidateStateProgressBar__cell {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 91px;
    height: 100%;
    text-align: center;
}

.CandidateStateProgressBar__cell-marker {
    position: absolute;
    height: 100%;
    border-right: dotted 2px #95989a;
    left: calc(50% - 1px);
}

.CandidateStateProgressBar__cell--filled:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
}

.CandidateStateProgressBar__cell--filled:first-of-type:before {
    left: 50%;
    width: 50%;
}

.CandidateStateProgressBar__cell--active:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 50%;
    border-bottom: solid 1px #d2d2d2;
}

.CandidateStateProgressBar__cell--active:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #ccc;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ccc;
}

.CandidateStateProgressBar__cell:first-of-type.CandidateStateProgressBar__cell--active:before {
    display: none;
}



.CandidateStateProgressBar--locked .CandidateStateProgressBar__cell--active:after {
    background-color: $black-marlin;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px $black-marlin;
}
.CandidateStateProgressBar--interesting .CandidateStateProgressBar__cell--active:after {
    background-color: $roman;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px $roman;
}
.CandidateStateProgressBar--phone-interview .CandidateStateProgressBar__cell--active:after {
    background-color: $malibu;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px $malibu;
}
.CandidateStateProgressBar--interview .CandidateStateProgressBar__cell--active:after {
    background-color: $sea-nymph;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px $sea-nymph;
}
.CandidateStateProgressBar--hired .CandidateStateProgressBar__cell--active:after {
    background-color: $casablanca;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px $casablanca;
}


.CandidateStateProgressBar--expiring {
    .CandidateStateProgressBar__cell--active:after {
        background-color: #cdcdcd;
        box-shadow: 0 0 0 2px #fff, 0 0 0 4px #cdcdcd;
    }
}
