@import '@truffls/design-system/scss/settings/_colors';

// TODO: move to design system when rebuilt

.tf-radio {
    line-height: 30px;
}

.tf-radio > input:disabled + .tf-radio__label {
    .tf-radio__faux {
        border-color: $color-shades-300 !important;
        box-shadow: none !important;

        &:after {
            background-color: $color-shades-300 !important;
        }
    }

    .tf-radio__label-text {
        color: $color-shades-300;
    }
}
