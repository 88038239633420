.DeleteCandidateViewContent__heading {
    font-weight: 600;
}

.DeleteCandidateViewContent__list {
    padding-left: 16px;
}

.DeleteCandidateViewContent__textarea {
    resize: none;
}
