@import '~@truffls/design-system/scss/settings/colors';
@import '../../../assets/styles/mixins/respond-to';

.SearchBarForm {
    position: relative;

    height: 34px;
}

.SearchBarForm__open-button {
    appearance: none;
    display: block;

    padding: 0;
    border: 0;
    background: transparent;

    border-radius: 50%;

    transition: background-color 150ms linear;

    &:focus,
    &:hover {
        outline: 0;

        background: rgba(255, 255, 255, 0.3);
    }
}

.SearchBarForm__open-icon {
    display: block;

    height: 24px;
    width: 24px;

    margin: 5px;

    fill: #ffffff;
}

.SearchBarForm__fields {
    display: none;

    flex-direction: row;

    position: relative;

    background-color: rgba(255, 255, 255, 0.56);
    border-radius: 2px;

    transition: width 250ms linear;
}

.SearchBarForm__input-query,
.SearchBarForm__input-type {
    display: block;

    appearance: none;

    font-size: 12px;
    line-height: 18px;

    &:focus {
        outline: 0;
    }
}

.SearchBarForm__input-query {
    flex-grow: 1;
    flex-shrink: 1;

    width: 100%;

    padding: 8px 16px 8px 40px;

    background-color: transparent;
    border: 0;
    border-radius: 2px;

    color: inherit;

    &::placeholder {
        color: $color-shades-700;
    }
}

.SearchBarForm__input-type {
    flex-grow: 0;
    flex-shrink: 0;

    display: none;

    position: relative;

    color: $color-shades-450;
}

.SearchBarForm__input-type-control {
    // TODO: Remove prefixed versions and use autoprefixer
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 100%;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 20px;

    border: 0;
    border-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;

    box-shadow: -1px 0 0 0 $color-shades-200;

    background-color: $color-background-input;

    outline: none;
    cursor: pointer;
}

.SearchBarForm__input-type-arrow-faux {
    content: '';
    position: absolute;

    top: 11px;
    right: 4px;

    height: 12px;
    width: 12px;

    background: url('../../../assets/images/ic-drop-down-bold.svg');

    fill: currentColor;

    pointer-events: none;
}

.SearchBarForm__icon {
    position: absolute;

    top: 50%;

    transform: translateY(-50%);
}

.SearchBarForm__icon--placeholder {
    left: 12px;

    height: 16px;
    width: 16px;
}

.SearchBarForm__icon--open {
    display: none;

    left: 8px;

    height: 24px;
    width: 24px;

    fill: green;
}

.SearchBarForm--expanded {
    color: $color-black;

    width: 100%;

    .SearchBarForm__open-button {
        display: none;
    }

    .SearchBarForm__fields {
        display: flex;

        background-color: #ffffff;
    }

    .SearchBarForm__input-query {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .SearchBarForm__input-type {
        display: block;
    }

    .SearchBarForm__placeholder-text {
        display: none;
    }

    .SearchBarForm__icon--placeholder {
        display: none;
    }

    .SearchBarForm__icon--open {
        display: block;
    }
}

@include respond-to('sm') {
    .SearchBarForm {
        width: 34px;
    }

    .SearchBarForm--expanded {
        width: 320px;
    }
}

@include respond-to('md') {
    .SearchBarForm {
        width: 180px;
    }

    .SearchBarForm__open-button {
        display: none;
    }

    .SearchBarForm__fields {
        display: block;
    }

    .SearchBarForm--expanded {
        width: 320px;
    }
}
