@use '~@truffls/design-system/scss/settings/colors' as colors;
@use '../../assets/styles/mixins/respond-to' as *;

.root {
    flex-grow: 1;

    padding: 8px;
    border-radius: 36px;
    border: 1px solid colors.$color-shades-100;
}

.content {
    display: flex;
    flex-direction: column;

    height: 100%;

    position: relative;

    margin: 0;
    padding: 0 0 24px;

    border: 1px solid colors.$color-shades-100;
    background-color: colors.$color-white;
    border-radius: 28px;

    text-align: left;
    font-weight: normal;
}

.promoteTag {
    position: absolute;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%) translateY(calc(-50% + -8px));

    padding: 16px 16px;

    border-radius: 24px;

    background-color: colors.$color-white;
    color: colors.$color-truffls-green-500;

    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
}

.header {
    padding: 32px 12px 16px;
}

.header:after {
    content: '';
    display: block;
    width: calc(100% - 4px);

    margin: 20px auto 0;

    border-bottom: 2px solid colors.$color-truffls-green-300;
}

.headerDetails {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.headerTitle,
.headerPrice {
    padding: 4px 0;

    color: colors.$color-truffls-green-500;
}

.headerTitle {
    flex-grow: 1;
    flex-shrink: 1;

    margin-top: 0;
    margin-bottom: -2px;

    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
}

.headerPrice {
    flex-grow: 0;
    flex-shrink: 0;

    min-width: 6.5ch;

    text-align: right;

    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
}

.headerSubtitle {
    flex-basis: 100%;

    margin-top: 8px;
    margin-bottom: -2px;
    padding-left: 4px;
    padding-right: 4px;

    font-size: 16px;
    font-weight: 600;
}

.action {
    padding: 12px 24px;
}

.sections {
    padding-top: 12px;
    padding-bottom: 12px;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.forceTopSpacing,
.promote {
    margin-top: 30px;
}

.promote {
    .content {
        background-color: colors.$color-truffls-green-500;
        color: colors.$color-white;
    }

    .headerTitle,
    .headerPrice {
        color: inherit;
    }
}

.root[aria-disabled='true'] {
    .content,
    .promoteTag {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
            background-color: rgba(255, 255, 255, 0.4);
            pointer-events: none;
        }
    }

    .content::after {
        border-radius: 28px;
    }

    .promoteTag::after {
        border-radius: 24px;
    }
}

@include respond-to('sm') {
    // Wrap in a media query for large screens
    .horizontal {
        .content {
            flex-direction: row;
            flex-grow: 0;

            padding-top: 12px;
            padding-bottom: 12px;
        }

        .promoteTag {
            transform: translateX(-50%) translateY(calc(-50% + -28px));
        }

        .header,
        .sections,
        .action {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;

            padding: 12px 16px;
        }

        .header {
            flex-direction: column;
            justify-content: center;
            row-gap: 16px;

            color: inherit;
        }

        .header::after {
            display: none;
        }

        .headerTitle {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .headerTitle,
        .headerPrice,
        .headerSubtitle {
            text-align: center;
        }

        .headerPrice {
            font-weight: normal;
        }

        .headerSubtitle {
            flex-basis: auto;
        }

        .action {
            order: 2;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .sections,
        .action {
            border-left: 1px solid colors.$color-shades-100;
        }

        &.forceTopSpacing,
        &.promote {
            margin-top: 36px;
        }
    }
}

@include respond-to('lg') {
    .headerTitle,
    .headerPrice {
        font-size: 28px;
        font-weight: 900;
        line-height: 30px;
    }
}
