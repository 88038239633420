.JobFormCallToActions {
    margin-bottom: 15px;

    word-spacing: 0px;
}

.JobFormCallToActions__call-to-action {
    margin-bottom: 10px;
    margin-right: 15px;

    word-spacing: normal;

    &:last-child {
        margin-right: 0;
    }
}

.JobFormCallToActions__call-to-action-title {
    display: block;

    font-weight: 600;
}
