@import '../../assets/styles/mixins/respond-to';

.CopyJobList {
    position: relative;
    margin: 0 -15px 50px;
    padding: 0;
    width: 100vw;

    list-style: none;
}

.CopyJobList__item {
    display: block;

    // Render a border for all children except the last one
    &:not(:last-child) {
        border-bottom: 1px solid #f4f4f4;
    }
}

@include respond-to('sm') {
    .CopyJobList {
        width: 100%;
        margin: 0;
        margin-bottom: 50px;
    }
}
