@import '../../assets/styles/config/colors';

.InfoBox {
    position: relative;

    margin: 10px;
    padding: 10px 16px;

    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);

    font-size: 12px;
    text-align: center;
}

.InfoBox__icon {
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    width: 25px;
}

.InfoBox__title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;

    &--success {
        color: $color-green;
    }

    &--danger {
        color: $color-red;
    }
}
