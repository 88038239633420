@import '~@truffls/design-system/scss/settings/colors';
@import '../../assets/styles/mixins/respond-to';

$max-result-items-count-lg: 5;

$backdrop-zindex: 1020;

.SearchBar {
    width: 34px;
    height: 34px;

    position: relative;
}

.SearchBar__backdrop {
    display: none;
}

.SearchBar__container {
    position: relative;
    z-index: $backdrop-zindex + 10;

    display: flex;
    align-items: center;

    color: $color-white;
}

.SearchBar__close-button {
    display: none;

    margin-left: 16px;
}

.SearchBar__results {
    position: fixed;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;

    display: none;

    background-color: $color-white;
    color: $color-black;
}

.SearchBar--expanded {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    width: auto;
    height: auto;

    .SearchBar__container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        padding: 15px;

        background-color: $color-black;
    }

    .SearchBar__close-button {
        display: block;
    }

    .SearchBar__results {
        display: block;
    }
}

@include respond-to('sm') {
    .SearchBar {
        position: relative;

        height: 34px;
        width: 34px;
    }

    .SearchBar__close-button {
        display: none;
    }

    .SearchBar__results {
        position: absolute;
        top: calc(100% + 4px);
        bottom: auto;

        display: flex;
        flex-direction: column;

        // ((max item height * max results item count) + (item border height * (max results item count - 1)))
        // +
        // (load button height + load button border height)
        max-height: ((74px * $max-result-items-count-lg) + (1px * ($max-result-items-count-lg - 1))) + (36px + 1px);

        overflow: hidden;

        border-radius: 2px;
    }

    .SearchBar__results-scrollable {
        flex-grow: 1;
        flex-shrink: 1;

        height: 100%;

        overflow-y: auto;
    }

    .SearchBar__results-scrollable::-webkit-scrollbar {
        width: 10px;
    }

    .SearchBar__results-scrollable::-webkit-scrollbar-thumb {
        border: 2px solid white;

        border-radius: 5px;

        background-color: $color-shades-200;
    }

    .SearchBar__results-scrollable::-webkit-scrollbar-thumb:hover {
        background-color: $color-shades-400;
    }

    .SearchBar__results-scrollable::-webkit-scrollbar-track {
        padding: 0 2px;

        background-color: $color-white;
    }

    .SearchBar__results-scrollable::-webkit-scrollbar-button {
        display: none;
    }

    .SearchBar__results-list {
        padding: 4px 0;
    }

    .SearchBar--expanded {
        .SearchBar__backdrop {
            display: block;

            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: $backdrop-zindex;

            background-color: rgba(0, 0, 0, 0.5);
        }

        .SearchBar__container {
            position: absolute;
            top: auto;
            left: auto;
            right: 0px;
            bottom: auto;

            padding: 0;

            // z-index: $backdrop-zindex + 10;

            background-color: transparent;
            // color: $color-white;
        }

        .SearchBar__close-button {
            display: none;
        }
    }
}

@include respond-to('md') {
    .SearchBar {
        width: 180px;
    }
}
