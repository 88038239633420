.ResumeLanguages__list-item {
    display: flex;
}

.ResumeLanguages__list-item__language {
    flex: 4;
}

.ResumeLanguages__list-item__level {
    flex: 6;
}
