.CandidateAvailability__input {
    margin-bottom: 0;
}

.CandidateAvailability__fields {
    display: flex;
    flex-direction: row;
}

.CandidateAvailability__field-slug {
    flex-grow: 1;
    flex-shrink: 1;
}

.CandidateAvailability__field-date {
    flex-grow: 0;
    flex-shrink: 0;

    width: 124px;

    margin-left: 12px;
}
