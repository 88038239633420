@import '~@truffls/design-system/scss/settings/_colors';
@import '../MessageItemWrapper/MessageItemWrapper.variables';

.MessageItemReadStatus {
    margin-top: -2px;
    padding-bottom: 6px;
    padding-right: $message-indentation;
}

.MessageItemReadStatus__body {
    font-size: 10px;
    color: $color-shades-450;
    letter-spacing: -0.3px;

    text-align: right;
}
