@import '../../assets/styles/mixins/respond-to';

.LegalAgreementDialog__form {
    display: flex;
    flex-direction: column;
}

.LegalAgreementDialog__body {
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    flex-shrink: 1;

    overflow-y: auto;
}


.LegalAgreementDialog__content,
.LegalAgreementDialog__image-frame {
    overflow-y: hidden;
}

.LegalAgreementDialog__content {
    order: 1;
}

.LegalAgreementDialog__image {
    display: block;

    max-width: 300px;

    margin-left: auto;
    margin-right: auto;
}

@include respond-to('md') {
    .LegalAgreementDialog__form {
        display: block;
    }

    .LegalAgreementDialog__body {
        display: flex;
        flex-direction: row;
    }

    .LegalAgreementDialog__content,
    .LegalAgreementDialog__image-frame {
        flex-grow: 1;
        flex-basis: 0;
    }

    .LegalAgreementDialog__content {
        order: 0;

        padding-right: 16px;
    }

    .LegalAgreementDialog__image-frame {
        display: flex;

        padding-top: 0;
        padding-left: 16px;
    }

    .LegalAgreementDialog__image {
        max-width: 100%;

        padding-top: 0;
        padding-bottom: 0;
    }

    .LegalAgreementDialog__form-action-accept {
        min-width: 120px;
    }
}
