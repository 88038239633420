@import '../../assets/styles/mixins/respond-to';

.HireConfirmationView__modal-header {
    text-align: center;
}

.HireConfirmationView__content {
    text-align: center;
}

.HireConfirmationView__content-icon {
    display: inline-block;
    margin-left: 15px;
    max-width: 120px;
}

.HireConfirmationView__content-text {
    margin: 10px auto;
    font-size: 12px;
    max-width: 350px;
}

.HireConfirmationView__content-candidate {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.HireConfirmationView__content-candidate-details {
    margin-left: 10px;
    max-width: calc(100% - 60px);
}

.HireConfirmationView__modal {
    max-width: none;
}

.HireConfirmationView__modal-footer {
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.HireConfirmationView__overlay-container {
    padding: 0 16px;
}

.HireConfirmationView__overlay-container .HireConfirmationView__content {
    padding-top: 15vh;
}

@include respond-to('sm') {
    .HireConfirmationView__content {
        padding-top: 0;
    }

    .HireConfirmationView__modal {
        max-width: 450px;
    }
}
