@import '~@truffls/design-system/scss/settings/colors';

.JobFormRequirementsListItemFormField {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 4px 8px 4px 12px;

    background: #666;
    border-radius: 14px;

    font-weight: bold;
    line-height: 20px;
    color: white;

    svg {
        fill: $color-shades-100;
    }
}

.JobFormRequirementsListItemFormField__display {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.JobFormRequirementsListItemFormField__action {
    flex-shrink: 0;

    appearance: none;

    width: 16px;
    height: 16px;

    margin-left: 8px;
    padding: 0;

    background: none;
    border: none;
    outline: none;

    text-align: center;

    svg {
        max-width: 100%;
        height: auto;
    }
}
