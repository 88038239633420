@import '~@truffls/design-system/scss/settings/_colors';

.MessageItemInfo {
    margin-top: 24px;
    margin-bottom: 24px;

    background-color: $color-white;
}

.MessageItemInfo__body {
    padding: 8px 11px;

    border-top: 0.5px solid $color-shades-200;
    border-bottom: 0.5px solid $color-shades-200;

    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.3px;

    text-align: center;
}

.MessageItemInfo__note {
    margin-bottom: 3px;

    color: $color-shades-450;
    font-style: italic;
}

.MessageItemInfo__content {
    color: $color-black;
}

.MessageItemInfo--legacy-theme {
    background-color: $color-shades-30;
}
