@use '~@truffls/design-system/scss/settings/colors' as colors;

.root {
    appearance: none;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 52px;

    padding: 8px 16px;

    border: none;
    border-radius: 4px;
    outline: 0;
    background: none;

    background-color: colors.$color-brand;

    line-height: 34px;
    font-weight: 600;
    font-size: inherit;
    text-align: center;
    text-transform: uppercase;

    &,
    &:hover,
    &:focus {
        text-decoration: none;
        color: colors.$color-white;
    }
}

.promote {
    background-color: colors.$color-white;

    &,
    &:hover,
    &:focus {
        color: colors.$color-truffls-green-500;
    }
}

.horizontal {
    max-width: 280px;
}
