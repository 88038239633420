@import '../../../assets/styles/config/colors';

.CandidateListHeaderColumnState {
    display: flex;

    width: 100%;
    /* Fixed height because of flexbox bugs in safari */
    height: 72px;
}

.CandidateListHeaderColumnState__column {
    display: flex;

    flex-direction: column;
    justify-content: flex-end;

    width: 20%;
    flex-grow: 1;
    align-items: center;
    text-align: center;
}

.CandidateListHeaderColumnState__column:nth-child(even) {
    justify-content: flex-start;

    &:after {
        content: "";

        display: block;
        flex-grow: 1;

        border-left: dotted 2px #95989a;
    }
}

.CandidateListHeaderColumnState__column-label {
    display: block;

    margin-bottom: 3px;

    padding-top: 4px;
    padding-bottom: 0px;

    padding-left: 0.4em;
    padding-right: 0.4em;

    border-bottom: 1px solid transparent;

    white-space: nowrap;

    .CandidateListHeaderColumnState__column--locked & {
        border-bottom-color: $black-marlin;
    }

    .CandidateListHeaderColumnState__column--interesting & {
        border-bottom-color: $roman;
    }

    .CandidateListHeaderColumnState__column--interview & {
        border-bottom-color: $sea-nymph;
    }

    .CandidateListHeaderColumnState__column--phone-interview & {
        border-bottom-color: $malibu;
    }

    .CandidateListHeaderColumnState__column--hired & {
        border-bottom-color: $casablanca;
    }
}
