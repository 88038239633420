.root {
    display: inline-flex;
    overflow: hidden;
    column-gap: 2ch;
}

.wrap {
    flex-wrap: wrap;
}

.part:not(:first-child):before {
    content: '–';
    display: inline-block;
    width: 2ch;
    text-align: center;
    margin-left: -2ch;
}
