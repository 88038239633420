.List {
    display: block;
    position: relative;
    list-style: none;

    margin: 0;
    padding: 0;
}

.List--padding {
    padding: 12px 0;
}
