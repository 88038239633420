@import '~@truffls/design-system/scss/settings/_colors';
@import '../../../assets/styles/mixins/respond-to';

$md-grid-gap: 12px;

.RequestDocumentsViewTiles {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1;
    justify-content: flex-start;

    margin-top: 16px;
}

.RequestDocumentsViewTiles__tile {
    display: flex;
    flex-direction: column;

    width: 100%;

    cursor: pointer;

    + .RequestDocumentsViewTiles__tile {
        margin-top: 8px;
    }

    // Overwrite design system
    .tf-checkbox__label {
        cursor: pointer;
    }

    &.tf-has-success {
        .tf-checkbox__label {
            color: $color-brand;
            font-weight: 600;
        }
    }
}

.RequestDocumentsViewTiles__tile-inner {
    margin-bottom: 0;
}

@include respond-to('md') {
    .RequestDocumentsViewTiles__tile {
        width: calc(1 / 3 * 100% - #{$md-grid-gap});

        margin-right: $md-grid-gap;
        margin-bottom: $md-grid-gap;

        + .RequestDocumentsViewTiles__tile {
            margin-top: 0;
        }

        .tf-card {
            display: flex;
            align-items: center;

            padding-top: 0;
            padding-bottom: 0;
        }

        .tf-checkbox__label {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;

            margin-bottom: 0;

            .tf-checkbox__label-text {
                flex: 1;
            }
        }
    }
}
