@import '../../../assets/styles/config';
@import '../../../assets/styles/mixins';
@import '../../Navigation/Navigation.settings.scss';
@import '../JobFormPageLayout/JobFormPageLayout.utils.scss';
@import './JobFormPageActions.settings.scss';

.JobFormPageActions {
    margin-bottom: 16px;
}

.JobFormPageActions--fixed {
    position: fixed;
    top: $navigation-bar-height;
    left: 0;
    right: 0;

    z-index: $zindex-job-form-page-actions;

    height: #{$job-form-page-actions-fixed-height}px;

    background: $body-bg;

    border-bottom: 1px solid $panel-default-border;

    box-shadow: 0 0 10px $panel-default-border;

    .JobFormPageActions__content {
        @include container;

        padding-top: 15px;
        padding-bottom: 15px;

        white-space: nowrap;
        overflow-x: auto;
    }
}
