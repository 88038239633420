// input,
// textarea {
//     &:focus {
//         &::-webkit-input-placeholder {
//             color: transparent;
//         }
//
//         &:-moz-placeholder {
//             color: transparent;
//         }
//
//         &::-moz-placeholder {
//             color: transparent;
//         }
//
//         &:-ms-input-playholder {
//             color: transparent;
//         }
//     }
// }

select.placeholder-active {
    color: $input-color-placeholder;

    // > option {
    //     color: $input-color;

    //     &[disabled] {
    //         color: $input-color-placeholder;
    //     }
    // }
}
