.CandidatePageHeader {
    max-width: 70%;

    margin-left: auto;
    margin-right: auto;

    line-height: 1.4;
}

.CandidatePageHeader__prefix {
    display: block;

    font-weight: normal;
}

.CandidatePageHeader__title {
    position: relative;
    display: block;

    margin-top: 15px;

    white-space: nowrap;
}

.CandidatePageHeader__title-content {
    white-space: normal;
    word-wrap: break-word;
}

.CandidatePageHeader__icon-link {
    display: inline-block;
    vertical-align: middle;
}

.CandidatePageHeader__icon {
    display: inline-block;

    vertical-align: baseline;
}

.CandidatePageHeader--skeleton {
    .CandidatePageHeader__title:before {
        content: '\00a0';

        display: inline-block;

        position: absolute;
        top: 50%;
        left: 50%;

        height: 0.8em;
        width: 70%;
        max-width: 420px;

        margin-top: -0.4em;

        background-color: #cccccc;
        border-radius: 250em;

        transform: translateX(-50%);
    }

    .CandidatePageHeader__title:after {
        content: '\00a0';

        display: inline-block;

        color: transparent;
    }
}
