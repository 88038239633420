@import '~@truffls/design-system/scss/settings/colors';
@import '../../assets/styles/mixins/respond-to';

.SignupSuccess {
    display: flex;
    flex-direction: column;

    margin: 0 auto;
}

.SignupSuccess__body {
    order: 1;
}

.SignupSuccess__headline:before {
    content: '';

    display: block;
    height: 8px;
    width: 56px;

    background-color: $color-truffls-green-500;
    border-radius: 5px;
}

.SignupSuccess__countdown {
    margin-bottom: 24px;
    padding: 0 32px;
}

.SignupSuccess__paragraph {
    margin-bottom: 12px;
}

.SignupSuccess__action-bar {
    margin-top: 24px;
}

@include respond-to('md') {
    .SignupSuccess {
        position: relative;

        display: block;

        height: 100%;
        min-height: 100%;
        width: 100%;
    }

    .SignupSuccess__aside {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;

        max-width: 50%;

        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 128px;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .SignupSuccess__aside-content {
        max-height: 100%;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        justify-content: flex-end;
    }

    .SignupSuccess__body {
        order: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;

        height: 100%;
        min-height: 100%;
    }

    .SignupSuccess__content {
        position: relative;
    }

    .SignupSuccess__header {
        display: block;
    }

    .SignupSuccess__countdown {
        position: absolute;
        top: 50%;
        left: 32px;
        z-index: 3;

        width: 324px;

        margin-bottom: 0;
        padding: 0;

        transform: translate(0, -50%);
    }
}
