@import '~@truffls/design-system/scss/settings/_colors';

fieldset.panel {
    border-color: $panel-default-border;

    .panel-heading {
        margin-top: 8px;
        margin-bottom: 4px;

        border-bottom: 0;

        background: transparent;

        text-transform: uppercase;
    }

    .panel-title {
        font-size: $font-size-h4;

        color: $text-color;
    }

    .panel-subtitle {
        display: block;
        margin-top: 5px;
        color: $color-shades-450;
        text-transform: none;
    }
}
