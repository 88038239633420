@import '~@truffls/design-system/scss/settings/_colors';

.SearchBarResultsList {
    display: block;

    margin: 0;
    padding: 0;

    list-style: none;
}

.SearchBarResultsList__item {
    display: block;

    padding: 0 8px;
}

.SearchBarResultsList__item-inner {
    border-bottom: 1px solid $color-shades-200;
}

.SearchBarResultsList__item:last-child {
    .SearchBarResultsList__item-inner {
        border-bottom: none;
    }
}
