.CandidateConversationPage--desktop {
    height: 100%;
    max-height: 602px;
}

.CandidateConversationPage__message-list {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;

    display: flex;
    position: relative;

    height: 100%;

    -webkit-overflow-scrolling: touch;
}
