@import '~@truffls/design-system/scss/settings/_colors';
@import '../../../assets/styles/config/breakpoints';
@import '../../../assets/styles/mixins';

$dropdown-caret-size: 6px;

.NavigationActions {
    display: flex;
    align-items: center;

    height: 64px;

    color: $color-shades-100;
}

.NavigationActions__action {
    margin-right: 12px;
    margin-left: 12px;
}

.NavigationActions__link {
    color: inherit;
    cursor: pointer;

    &:hover,
    &:focus {
        color: inherit;
    }
}

.NavigationActions__primary-action,
.NavigationActions__secondary-action,
.NavigationActions__action--sm,
.NavigationActions__action--md,
.NavigationActions__action--lg {
    display: none;
}

.NavigationActions__search {
    margin-right: 12px;
    margin-left: 12px;
}

.NavigationActions__dropdown {
    position: relative;
    display: block;
    list-style: none;
}

.NavigationActions .dropdown-toggle {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    height: 64px;

    padding: 0 24px;

    color: inherit;
    text-align: right;

    background-color: $color-black !important; // overwrite bootstrap onClickOutside

    &:focus,
    &:hover {
        background-color: rgba($color-white, 0.2) !important; // overwrite bootstrap
        text-decoration: none;
    }
}

.NavigationActions__dropdown-toggle {
    display: none;
}

.NavigationActions .dropdown-toggle .caret {
    vertical-align: middle;
    border-right-width: $dropdown-caret-size;
    border-left-width: $dropdown-caret-size;
    border-top-width: $dropdown-caret-size;
}

@include respond-to('sm') {
    .NavigationActions__action--sm {
        display: inline-block;
    }

    .NavigationActions__dropdown-toggle {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 24px;
        vertical-align: middle;
        font-size: 14px;
        line-height: 18px;
    }

    .NavigationActions__dropdown-toggle-title,
    .NavigationActions__dropdown-toggle-subtitle {
        display: inline-block;

        max-width: 200px;

        overflow: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .NavigationActions__dropdown-toggle-title {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.2px;
        color: $color-brand;
        margin-bottom: 4px;
    }

    .NavigationActions__dropdown .dropdown-menu {
        left: auto;
        right: 0;
    }
}

@include respond-to('md') {
    .NavigationActions__action--md {
        display: inline-block;
    }
}

@include respond-to('lg') {
    .NavigationActions__primary-action {
        // margin-right: 153px;
    }
}
