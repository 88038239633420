@import '~@truffls/design-system/scss/settings/_colors';
@import '../variables';

.TutorialDesktopBackgroundAnimationWrapper {
    position: absolute;
    bottom: 0;
    right: 0;

    width: 10%;
    height: 100%;
    overflow: visible;
    // final translate values as animation fallback
    transform: translate(100%, 100%);

    z-index: $z-index-background;
}

.TutorialDesktop--step {
    .TutorialDesktopBackgroundAnimationWrapper {
        // final translate values as animation fallback
        transform: translate(-1000%, 100%);
    }
}

.TutorialDesktopBackground {
    width: 400%;
    height: 100%;

    background-color: $color-brand;

    // final translate values as animation fallback
    transform: translate(-100%, -100%);
}

.TutorialDesktop--step {
    .TutorialDesktopBackground {
        // final translate values as animation fallback
        transform: translate(-1%, -100%);
    }
}
