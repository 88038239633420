.FilterAndOrderingPageMobile__form {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
}

.FilterAndOrderingPageMobile__content {
    flex-grow: 1;
    flex-shrink: 1;

    overflow-y: auto;
}

.FilterAndOrderingPageMobile__section {
    margin-bottom: 1em;

    &:not(:last-child) {
        border-bottom: 1px solid #d8d8d8;
    }
}

.FilterAndOrderingPageMobile__section-title {
    margin-bottom: 0.5em;

    padding: 0 16px;

    font-size: 16px;
    font-weight: bold;
}

.FilterAndOrderingPageMobile__option {
    display: block;

    padding: 4px 0;
}

.FilterAndOrderingPageMobile__option-control {
    margin: 0 16px;
}

.FilterAndOrderingPageMobile__option-label {
    font-weight: normal;
}
