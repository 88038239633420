@import '~@truffls/design-system/scss/settings/_colors';

.OverlayUserMenu__nav {
    width: 100%;
    padding-left: 16px;

    overflow-y: auto;
}

.OverlayUserMenu__nav-button {
    display: block;
    width: 100%;
    height: 44px;

    padding: 0;
    text-align: left;
    background-color: transparent;

    border: none;
    border-bottom: solid 1px #eaeaea;

    color: inherit;
    font-size: 16px;
    line-height: 42px;
    text-decoration: none;

    &,
    &:active,
    &:focus,
    &:hover {
        color: inherit;
        text-decoration: inherit;
    }

    &:last-child {
        border-bottom: none;
    }

    &--logout {
        display: inline;

        > span {
            color: $color-brand;
            text-decoration: underline;
        }
    }

    &--with-dot {
        margin-left: 10px;

        &::before {
            content: '•';
            margin-right: 10px;
            font-size: 20px;
        }
    }
}

.OverlayUserMenu__nav-seperator {
    margin-top: -1px;
    width: 100%;
    height: 1px;
    background-color: #4c4c4c;
}
