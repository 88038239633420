@import '~@truffls/design-system/scss/settings/_colors.scss';

$datepicker__triangle-size: 20px;
$datepicker__border-color: $color-harvard-crimson-700;
$datepicker__background-color: $color-white;

.DateInput__input {
    font-variant-numeric: tabular-nums;
}

.DateInput__calendar {
    position: relative;

    background-color: $color-white;
    border-radius: 4px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

    .react-datepicker__month-container {
        padding: 0 4px;
    }

    .react-datepicker__header {
        border-bottom: 1px solid $color-shades-100;
    }

    .react-datepicker__current-month {
        padding: 8px 8px;

        line-height: 28px;
        text-align: center;
    }

    .react-datepicker__navigation {
        appearance: none;

        position: absolute;
        top: 8px;

        display: block;

        height: 28px;
        width: 28px;

        background-color: transparent;

        border: 1px solid $color-shades-300;
        border-radius: 4px;

        overflow: hidden;
        white-space: nowrap;
        text-indent: 100px;

        &:after {
            content: '–';

            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;

            line-height: 28px;
            text-align: center;
            text-indent: 0;
        }

        &.react-datepicker__navigation--previous {
            left: 8px;

            &:after {
                content: '<';
            }
        }

        &.react-datepicker__navigation--next {
            right: 8px;

            &:after {
                content: '>';
            }
        }
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
        width: 36px;
        height: 36px;

        line-height: 36px;
        text-align: center;
    }

    .react-datepicker__day-names {
        display: flex;
        flex-direction: row;
    }

    .react-datepicker__month {
        display: flex;
        flex-direction: column;
    }

    .react-datepicker__week {
        display: flex;
        flex-direction: row;
    }

    .react-datepicker__day {
        border-radius: 4px;
    }

    .react-datepicker__day:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .react-datepicker__day--outside-month {
        color: $color-shades-400;
    }
}

.DateInput .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2000;
}

.DateInput__popper {
    z-index: 3;
}

.DateInput__popper .react-datepicker__triangle {
    position: absolute;

    margin-top: -12px;
    margin-right: 36px;

    height: 12px;
    width: 24px;

    overflow: hidden;

    z-index: 3;

    &:before {
        display: block;

        content: '';

        height: 16px;
        width: 16px;

        margin: 0 auto;

        background-color: $color-white;

        transform: rotate(45deg);
        transform-origin: center center;
    }
}

.DateInput__popper[data-placement^='bottom'] {
    margin-top: 16px;

    .react-datepicker__triangle {
        top: 0;

        margin-top: -12px;

        &:before {
            margin-top: 4px;
            box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
        }
    }
}

.DateInput__popper[data-placement^='top'] {
    margin-bottom: 16px;

    .react-datepicker__triangle {
        bottom: 0;

        margin-bottom: -12px;

        &:before {
            margin-top: -8px;
            box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
        }
    }
}
