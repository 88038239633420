.JobFormRequirementsCreateFormField {
    display: flex;
    flex-direction: row;

    margin-bottom: 24px;
}

.JobFormRequirementsCreateFormField__input {
    flex-grow: 1;
    flex-shrink: 1;
}

.JobFormRequirementsCreateFormField__submit-button {
    min-width: 0;

    margin-left: 12px;
    padding: 11px;
}

.JobFormRequirementsCreateFormField__submit-button-icon {
    display: block;

    fill: currentColor;

    height: 18px;
    width: 18px;
}
