@import '../../assets/styles/_config';

.BrowserUnsupported {
    padding: 40px 0;
    text-align: center;
    border-bottom: solid 1px $gray-lighter;
}

.BrowserUnsupported__title {
    margin-bottom: 30px;
}

.BrowserUnsupported__contact-cta {
    margin: 30px 0;
}
