.CandidateDocumentsPage {
    height: 100%;

    display: flex;
    flex-direction: column;
}

.CandidateDocumentsPage__scroll-zone {
    overflow-y: scroll;

    padding: 13px 16px 0;
}