@import '../../assets/styles/mixins/respond-to';

.ListLoadingIndicator {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgba(255, 255, 255, 0.93);

    z-index: 2;
}

.ListLoadingIndicator__text {
    margin-top: 32px;

    font-size: 15px;
    text-align: center;
}

@include respond-to ('sm') {
    .ListLoadingIndicator__text {
        font-size: 17px;
    }
}
