@import "../../assets/styles/_config.scss";

.Dialog {
    overflow: hidden;

    // Extracted from DialogPageContainer

    position: relative;

    // max-width: 540px;

    overflow: hidden;

    margin: 0 auto;

    background-color: $color-white;
    background-clip: padding-box;

    border-radius: 10px;

    outline: 0;

    box-shadow: 0 3px 9px rgba(0, 0, 0, .2);
}

.Dialog--no-shadow {
    box-shadow: none;
}

.Dialog__icon,
.Dialog__title,
.Dialog__text {
    margin-top: 30px;
}

.Dialog__title,
.Dialog__text,
.Dialog__body,
.Dialog__action-bar {
    padding-left: 30px;
    padding-right: 30px;

    .Dialog--shrink-content & {
        padding-left: 15%;
        padding-right: 15%;
    }
}

.Dialog__icon {
    height: 220px;
    width: 220px;

    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.Dialog__title,
.Dialog__text {
    margin-bottom: 30px;

    text-align: center
}

.Dialog__title:last-child,
.Dialog__text:last-child {
    margin-bottom: 45px;
}

.Dialog__title {
    font-weight: normal;
    font-size: 28px;
}

.Dialog__title + .Dialog__text {
    margin-top: -10px;
}
