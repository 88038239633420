@import '~@truffls/design-system/scss/settings/_colors';

.TrialStatusBanner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 48px;

    background-color: $color-truffls-green-500;
    color: $color-white;

    text-align: center;
    line-height: 24px;
    font-weight: 600;
}

.TrialStatusBanner__icon {
    display: inline-block;

    height: 24px;
    width: 24px;

    margin-right: 12px;

    fill: currentColor;
}

.TrialStatusBanner__link {
    text-decoration: underline;

    color: inherit;
}
