.OverlayLanguageMenu__nav {
    width: 100%;
    padding-left: 16px;
}

.OverlayLanguageMenu__nav-button {
    display: block;

    width: 100%;
    height: 44px;

    padding-right: 16px;

    background: none;

    border: none;
    border-bottom: solid 1px #eaeaea;

    color: inherit;
    font-size: 16px;
    line-height: 42px;
    text-decoration: none;

    -webkit-appearance: none;

    &,
    &:active,
    &:focus,
    &:hover {
        color: inherit;
        text-decoration: inherit;
    }

    &:last-child {
        border-bottom: none;
    }
}
