@import '~@truffls/design-system/scss/settings/colors';
@import '../../../assets/styles/mixins/respond-to';

$container-horizontal-margin: 20px;
$item-vertical-margin: 12px;
$item-horizontal-margin: 16px;

$item-size-ratio: 2.5;
$item-widths: (
    'small': 80px,
    'medium': 100px,
    'large': 120px,
    'x-large': 140px
);

@function get-width($key) {
    @return map-get($item-widths, $key);
}

@function get-height($key) {
    $width: map-get($item-widths, $key);
    @return $width / $item-size-ratio;
}

@mixin size($key) {
    width: get-width($key);
    height: get-height($key);
}

.SignupPageReferenceCompanies {
    padding: 32px ($container-horizontal-margin - $item-horizontal-margin);

    flex-shrink: 0;
}

.SignupPageReferenceCompanies__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    margin: ($item-vertical-margin * -1) auto;
}

.SignupPageReferenceCompanies__title-container {
    margin: $item-vertical-margin $item-horizontal-margin;
    margin-bottom: $item-vertical-margin * 2;

    text-align: center;
}

.SignupPageReferenceCompanies__title {
    display: inline-block;

    width: 100%;

    color: $color-white;
    background-color: $color-black;

    padding: 1px 2px;

    font-style: italic;
    font-weight: 800;
    letter-spacing: -0.1px;
}

.SignupPageReferenceCompanies__item {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include size('x-large');

    margin: $item-vertical-margin $item-horizontal-margin;

    vertical-align: middle;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.SignupPageReferenceCompanies__company-image {
    display: block;

    width: 100%;
    height: 100%;

    opacity: 0;
}

@include respond-to('md') {
    .SignupPageReferenceCompanies__title-container {
        text-align: left;
    }

    .SignupPageReferenceCompanies__title {
        width: auto;

        padding-left: 6px;
    }

    .SignupPageReferenceCompanies__content {
        justify-content: space-between;
    }

    .SignupPageReferenceCompanies__item {
        @include size('small');
    }
}

@media (min-width: 1128px) {
    .SignupPageReferenceCompanies__item {
        @include size('medium');
    }
}

@media (min-width: 1296px) {
    .SignupPageReferenceCompanies__item {
        @include size('large');
    }
}

@media (min-width: 1462px) {
    .SignupPageReferenceCompanies__item {
        @include size('x-large');
    }
}

@supports (object-fit: contain) {
    .SignupPageReferenceCompanies__item {
        // We have to override the inline style.
        background-image: none !important;
    }

    .SignupPageReferenceCompanies__company-image {
        width: auto;
        height: auto;

        max-width: 100%;
        max-height: 100%;

        object-fit: contain;

        opacity: 1;
    }
}
