@import '~@truffls/design-system/scss/settings/_colors';

.root {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin: -1px 0 0;
    padding: 12px 24px;
    cursor: pointer;

    font-weight: 600;

    border-bottom: 1px solid $color-shades-200;
}
.root:hover {
    background-color: rgba($color-shades-200, 0.12);
}

.selected,
.selected:hover {
    background-color: rgba($color-shades-200, 0.24);
}

.label {
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 4px;

    flex-grow: 1;
}

.indicator {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    // width: 112px;
    min-height: 24px;
}

.indicatorInner {
    display: block;

    min-width: 32px;
    min-height: 24px;

    padding-left: 4px;
    padding-right: 4px;

    background-color: $color-shades-900;

    color: $color-white;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    font-style: italic;
}

.expiration {
    font-weight: 500;
    align-self: center;
    flex-basis: 100%;
}

.text {
    flex-grow: 1;

    font-size: 14px;
    line-height: 24px;
}

.radio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-right: 34px;
    cursor: pointer;
}

.radioLabel {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 24px;
}
