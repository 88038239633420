@import '../../assets/styles/config';
@import '../../assets/styles/mixins';

// TODO: Feels wrong that a stateless component has to import something from a container component.
@import '../../containers/Navigation/Navigation.settings.scss';

.root {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-width: 0 0 1px;
    border-radius: 0;
}

.content {
    max-width: 100%;

    padding-top: 8px;
    padding-bottom: 8px;
}

.text,
.jobTitle,
.editLink {
    display: block;
}

.jobTitle {
    max-width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;

    vertical-align: bottom;
    white-space: nowrap;
}

@include respond-to('sm') {
    .root {
        position: fixed;
        top: $navigation-bar-height;
        right: 0;
        left: 0;
        z-index: 90;

        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
        margin-left: 0;
    }

    .content {
        padding: 0;

        min-height: 50px;
        line-height: 50px;

        white-space: nowrap;
    }

    .text,
    .jobTitle,
    .editLink {
        display: inline-block;
    }

    .jobTitle {
        max-width: 200px;
    }
}

@include respond-to('md') {
    .jobTitle {
        max-width: 350px;
    }
}
