@use '~@truffls/design-system/scss/settings/colors' as colors;

.root {
    display: block;
    appearance: none;

    height: 52px;
    width: 100%;
    max-width: 232px;

    padding: 8px 16px;

    border: 1px solid colors.$color-shades-800;
    border-radius: 4px;
    outline: 0;
    background-color: colors.$color-white;

    line-height: 34px;
    font-weight: 600;
    font-size: inherit;
    text-align: center;
    text-transform: uppercase;

    &,
    &:hover,
    &:focus {
        text-decoration: none;
        color: inherit;
    }
}

.wide {
    max-width: none;
}
