@import '../../assets/styles/mixins/respond-to';

.CandidateListItem {
    display: flex;
    position: relative;
    flex-basis: flex-end;
    height: 70px;
    background-color: #fff;
    transition: box-shadow 0.3s;

    &,
    &:active,
    &:focus,
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.CandidateListItem:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    z-index: 1;
}

.CandidateListItem__rating {
    display: inline-block;
    flex-shrink: 0;
    width: 70px;
    height: 100%;
}

.CandidateListItem__created-date {
    display: none;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 10px;
    width: 110px;
    font-size: 15px;
}

.CandidateListItem__details {
    display: inline-block;
    vertical-align: top;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
    overflow: hidden;
}

.CandidateListItem__progress {
    display: none;
}

.CandidateListItem__expiration-explanation {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 445px;
    padding: 20px;
    background-color: #fff;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}

.CandidateListItem__expiration-explanation-link {
    margin: 0 1px;
    padding: 0;
    border: none;
    vertical-align: top;
}


.CandidateListItem--skeleton {
    &:hover {
        box-shadow: none;
    }

    .CandidateListItem__created-date,
    .CandidateListItem__rating {
        text-align: center;
        line-height: 70px;

        &:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #ccc;
        }
    }

    .CandidateListItem__created-date:after {
        width: 70px;
        border-radius: 25em;
    }
}

.CandidateListItem--faded {
    .CandidateListItem__rating,
    .CandidateListItem__created-date {
        opacity: 0.5;
    }
}


@include respond-to('sm') {
    .CandidateListItem {
        height: 80px;
        margin-left: 0;
        padding-right: 0;
        border: none;
    }

    .CandidateListItem__progress {
        display: inline-block;
        height: 100%;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .CandidateListItem:hover .CandidateListItem__expiration-explanation {
        opacity: 1;
        pointer-events: all;
    }

    .CandidateListItem--skeleton {
        .CandidateListItem__rating {
            line-height: 80px;
        }
    }
}

@include respond-to('md') {
    .CandidateListItem__created-date {
        display: flex;
    }
}
