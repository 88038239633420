@import '../../assets//styles/mixins/respond-to';

.notAllowedToOrder {
    padding-bottom: 30px;
}

@include respond-to('sm') {
    .root {
        padding-bottom: 40px;
    }
}
