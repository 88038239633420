
html,
body,
body > .viewport {
    height: 100%;
}

// DIRTY!!! Need to do because of modals
body {
    overflow-y: scroll !important;
    padding-right: 0 !important;
}
