@import '~@truffls/design-system/scss/settings/_colors';

.MessageList {
    display: block;

    width: 100%;

    margin: 0;
    padding: 0;

    list-style: none;

    background-color: $color-shades-30;
}

.MessageList--legacy-theme {
    background-color: $color-white;
}
