@import '~@truffls/design-system/scss/settings/colors';

.DocumentList {
    display: block;

    margin: 0;
    padding: 0;
}

.DocumentList__item {
    display: block;

    border-bottom: 1px solid $color-shades-200;

    list-style: none;
}
