@import '~@truffls/design-system/scss/settings/_colors';
@import '../variables';

.TutorialMobileBackgroundAnimationWrapper {
    position: absolute;
    bottom: 0;
    right: 0;

    width: 100%;
    height: 1%;
    overflow: visible;
    // final translate values as animation fallback
    transform: translate(100%, 100%);

    z-index: $z-index-background;
}
.TutorialMobile--step {
    .TutorialMobileBackgroundAnimationWrapper {
        // final translate values as animation fallback
        transform: translate(0%, -10000%);
    }
}

.TutorialMobileBackground {
    width: 100%;
    height: 120px;

    background-color: $color-brand;

    // final translate values as animation fallback
    transform: translate(-100%, -100%);
}
.TutorialMobile--step {
    .TutorialMobileBackground {
        // final translate values as animation fallback
        transform: translate(0%, 0%);
    }
}
