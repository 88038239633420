.ListItemIcon {
    display: block;

    flex-shrink: 0;
    flex-grow: 0;

    height: 24px;
    width: 24px;

    margin-right: 8px;
}
