.DeleteCandidateViewMobile__form {
    display: flex;
    flex-direction: column;
}

.DeleteCandidateViewMobile__content {
    padding: 16px;

    flex-grow: 1;
    flex-shrink: 1;

    overflow-y: auto;
}
