@import '~@truffls/design-system/scss/settings/colors';
@import '../../../assets/styles/mixins/respond-to';

.CheckoutProductPageHeadline {
    margin-top: 16px;
    margin-bottom: 16px;

    color: $color-shades-900;
}

.CheckoutProductPageHeadline__title {
    margin-top: 0;
    margin-bottom: 16px;

    font-size: 48px;
    line-height: 1;
    font-style: italic;
    font-weight: 700;
}

.CheckoutProductPageHeadline__subtitle {
    margin-top: 0;
    margin-bottom: 22px;

    line-height: 1.29;
}

@include respond-to('sm') {
    .CheckoutProductPageHeadline {
        display: flex;
        flex-direction: row;

        margin-top: 32px;
        margin-bottom: 32px;
    }

    .CheckoutProductPageHeadline__title {
        flex-shrink: 0;
    }

    .CheckoutProductPageHeadline__subtitle {
        align-self: flex-end;

        margin-left: 20px;
        margin-bottom: 20px;
    }
}
