@import '~@truffls/design-system/scss/settings/colors';

.JobFormRequirementsListItemPlaceholder {
    height: 28px;

    margin: 0 6px;

    background: #f2f2f2;
    border: 1px dashed $color-shades-200;
    border-radius: 14px;

    color: $color-shades-450;
}
