.HideCandidateView__modal-header {
    text-align: center;
}

.HideCandidateView__modal-footer {
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.HideCandidateView__overlay-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0 16px;

    overflow-y: auto;
}

.HideCandidateView__overlay-container .HideCandidateView__content {
    margin-top: 16px;
}

.HideCandidateView__overlay-container .HideCandidateView__checkbox {
    padding-bottom: 16px;
}


.HideCandidateView__checkbox {
    margin-top: 4px;
}

.HideCandidateView__checkbox-label {
    font-weight: normal;
    cursor: pointer;
}

