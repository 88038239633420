@import '../../../assets/styles/mixins/respond-to';

.SignupPageContainer__spacer {
    display: none;
}

@include respond-to('md') {
    .SignupPageContainer {
        display: flex;
        flex-direction: row;
    }

    .SignupPageContainer__spacer {
        display: block;

        width: 40%;
        flex-shrink: 1;
    }

    .SignupPageContainer__content {
        flex-grow: 1;
        flex-shrink: 0;
    }
}
