@import '~@truffls/design-system/scss/settings/colors';

$request-documents__margin-top: 20px;

.RequestDocumentsViewMobile__form {
    display: flex;
    flex-direction: column;
}

.RequestDocumentsViewMobile__container {
    margin-top: $request-documents__margin-top;
    padding: 0 16px 16px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
}

.RequestDocumentsViewMobile__scroll-indicator {
    position: absolute;
    top: $request-documents__margin-top;
    left: 0;
    right: 0;
    height: 40px;

    z-index: 1;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), $color-white);
}

.RequestDocumentsViewMobile__container__title {
    font-size: 12px;
    color: $color-shades-450;
}

.RequestDocumentsViewMobile__form__actions {
    flex-direction: row;
    justify-content: flex-end;

    &--in-scroll-area {
        border-top: 0.5px solid $color-shades-100;
    }
}
