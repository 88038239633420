.EmailAndPasswordLoginForm {
    max-width: 400px;

    margin-right: auto;
    margin-left: auto;
}

.EmailAndPasswordLoginForm__notes {
    margin: 16px 0;

    text-align: center;
}
