.MobileAppNavBar {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 0 0.07em;
    width: 100%;
    height: 0.3em;
    background-color: #f6f6f6;
}

.MobileAppNavBar--border-bottom {
    border-bottom: solid 0.01em #ddd;
}

.MobileAppNavBar__left-section,
.MobileAppNavBar__right-section {
    display: flex;
    align-items: center;
    width: 0.3em;
}

.MobileAppNavBar__right-section {
    justify-content: flex-end;
}

.MobileAppNavBar__center-section {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    max-width: calc(100% - 0.6em);
}
