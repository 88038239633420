.TutorialDesktopContentText {
    position: relative;
}

.TutorialDesktopContentText__title {
    font-size: 34px;
    font-weight: 600;
    letter-spacing: -0.4px;

    margin-top: 0;
    margin-bottom: 24px;
}
.TutorialDesktop--step {
    .TutorialDesktopContentText__title {
        width: 385px;
    }
}

.TutorialDesktopContentText__paragraph {
    margin-bottom: 0;
}
