
@mixin container() {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

@mixin make-column($columns, $gutter: $grid-gutter-width) {
    @include make-xs-column($columns, $gutter);
}

@mixin make-column-offset($columns) {
    @include make-xs-column-offset($columns);
}

@mixin make-column-push($columns) {
    @include make-xs-column-push($columns);
}

@mixin make-column-pull($columns) {
    @include make-xs-column-pull($columns);
}
