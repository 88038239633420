@use "../../assets/styles/mixins/respond-to" as *;

.root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;

    width: 100%;
}

.item {
    display: flex;
    flex-direction: row;
    justify-content: center;

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
}

@include respond-to('md') {
    .root {
        flex-wrap: nowrap;
    }
}
