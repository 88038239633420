.text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

label.required {
    &:after {
        content: '*';
    }
}

.input-group {
    .input-group-btn {
        select.btn.btn-default {
            position: relative;

            padding-top: $padding-base-vertical;
            padding-bottom: $padding-base-vertical;

            background-color: $input-bg;

            @include form-control-focus;

            &:focus {
                z-index: 3;
            }
        }
    }
}

.help-block a {
    text-decoration: underline;
    color: inherit;
}

.has-error {
    .input-group {
        .input-group-btn {
            select.btn.btn-default {
                color: $state-danger-text;

                border-color: $state-danger-text;
                @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075)); // Redeclare so transitions work

                &:focus {
                    border-color: darken($state-danger-text, 10%);
                    $shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px lighten($state-danger-text, 20%);
                    @include box-shadow($shadow);
                }
            }
        }
    }
}
