@import '~@truffls/design-system/scss/settings/_colors';

.MessageItemStatus {
    margin-top: 16px;
    margin-bottom: 16px;

    text-align: center;
}

.MessageItemStatus__body {
    display: inline-block;

    margin: 0 auto;
    padding: 2px 8px;

    background-color: $color-truffls-green-400;
    border-radius: 19px;

    font-size: 12px;
    color: $color-white;
    line-height: 16px;
    letter-spacing: 0.2px;
}
