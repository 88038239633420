@import '~@truffls/design-system/scss/settings/colors.scss';

.CandidateAlsoAppliedFor__list {
    margin-top: -1px;
}

.CandidateAlsoAppliedFor__more-container {
    padding-top: 5px;
    padding-bottom: 3px;
}

.CandidateAlsoAppliedFor__more-link {
    appearance: none;

    display: block;
    border: none;
    border-radius: 0;
    background: none;
    font: inherit;
    line-height: inherit;
    padding: 0;

    text-decoration: underline;

    color: $color-brand;

    &:hover,
    &:focus {
        color: $color-truffls-green-700;
    }
}
