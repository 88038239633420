@import '../../../assets/styles/mixins/respond-to';

.SignupPageLayout {
    min-height: 100%;
    height: 100%;
}

.SignupPageLayout__content {
    display: flex;
    flex-direction: column;

    min-height: 100%;
    height: 100%;

    padding: 88px 0 0;
}

@include respond-to('md') {
    .SignupPageLayout__spacer {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        // z-index: 1010; // This has to be higher than the navigation bar

        display: flex;
        flex-direction: column;
    }

    .SignupPageLayout__spacer-background {
        height: 100%;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        background-color: #000000;

        background-image: url(../../../assets/images/signup/spacer/background@1x.png);

        @media (min-resolution: 2dppx) {
            background-image: url(../../../assets/images/signup/spacer/background@2x.png);
        }

        @media (min-resolution: 3dppx) {
            background-image: url(../../../assets/images/signup/spacer/background@3x.png);
        }

        @media (min-resolution: 4dppx) {
            background-image: url(../../../assets/images/signup/spacer/background@4x.png);
        }
    }

    .SignupPageLayout__content {
        flex-grow: 1;
        flex-basis: 0%;

        margin-left: 40%;

        padding-top: 94px;
    }

    .SignupPageLayout__content-container {
        margin: auto 0;
    }
}
