.ListHeaderFilterToggle {
    display: block;

    padding: 0 0.3em;
    margin: 0;

    border: 0;
    background: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.ListHeaderFilterToggle__icon {
    height: 1em;
}
