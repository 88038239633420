$base-width: 256;
$base-height: 512;

:export {
    baseWidth: $base-width;
    baseHeight: $base-height;
}

.MobileApp {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: auto;
    backface-visibility: hidden;
}

.MobileApp__frame {
    max-width: 100%;
    max-height: 100%;
}

.MobileApp__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 11%;
    bottom: 11%;
    left: 6%;
    right: 5%;
    background-color: #f6f6f6;
    border: solid 0.02em #333;
}
