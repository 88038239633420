@import "../tools.scss";

.MobileAppInfoMessage {
    padding: 0.07em 0.07em 0;
    background-color: #f6f6f6;
}

.MobileAppInfoMessage__bubble {
    font-size: 0.11em;

    margin: 0 auto;
    padding:
        convert-absolute-to-relative(5, 0.11)
        convert-absolute-to-relative(7, 0.11);

    border-radius: convert-absolute-to-relative(2, 0.11);
    box-shadow:
        0
        convert-absolute-to-relative(1, 0.11)
        convert-absolute-to-relative(1, 0.11)
        rgba(0, 0, 0, 0.1);
    background-color: #90c6e2;

    color: #fff;
    text-align: center;
}
