@import "../../../assets/styles/config/colors";
@import "../tools.scss";

.MobileAppChatMessage {
    display: flex;
    position: relative;
    padding: 0.07em 0.07em 0;
    background-color: #f6f6f6;
}

.MobileAppChatMessage__portrait-image {
    flex-shrink: 0;
    flex-grow: 0;

    width: 1.7em;
    height: 1.7em;

    font-size: 0.1em;
}

.MobileAppChatMessage__chat-bubble {
    font-size: 0.11em;

    width: 80%;

    margin:
        0
        convert-absolute-to-relative(5, 0.11);
    padding:
        convert-absolute-to-relative(3, 0.11)
        convert-absolute-to-relative(5, 0.11);

    border-radius: convert-absolute-to-relative(2, 0.11);
    box-shadow:
        0
        convert-absolute-to-relative(1, 0.11)
        convert-absolute-to-relative(1, 0.11)
        rgba(0, 0, 0, 0.1);
}

.MobileAppChatMessage__chat-bubble__message {
    word-wrap: break-word; // fallback for non supporting browsers
    overflow-wrap: break-word;
}

.MobileAppChatMessage__chat-bubble__timestamp {
    font-size: 0.009em;

    margin-top: 0.0025em;
    color: #a4a4a4;
    float: right;
}

.MobileAppChatMessage--from-recruiter {
    .MobileAppChatMessage__chat-bubble {
        background-color: #fff;
    }
}

.MobileAppChatMessage--from-applicant {
    &.MobileAppChatMessage {
        flex-direction: row-reverse;
    }

    .MobileAppChatMessage__chat-bubble {
        background-color: $primary-color;
        color: #fff;
    }

    .MobileAppChatMessage__chat-bubble__timestamp {
        color: #fff;
        opacity: 0.8;
    }
}
