@import '../../../../assets/styles/config/colors';

.MobileAppNavBarTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    line-height: 1;
    text-align: center;
}

.MobileAppNavBarTitle__title {
    font-size: 0.12em;

    font-weight: 600;
    width: 100%;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.MobileAppNavBarTitle__title--green {
    color: $primary-color;
}

.MobileAppNavBarTitle__subtitle {
    font-size: 0.08em;

    color: #999;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
