@import '../../assets/styles/config.scss';
@import '~@truffls/design-system/scss/settings/_colors';
@import '~@truffls/design-system/scss/components/button/_settings';

@mixin LoaderButtonV2__spinner-color($color) {
    .LoaderButtonV2__spinner:after {
        border-top-color: $color;
        border-right-color: $color;
        border-bottom-color: $color;
    }
}

@keyframes LoaderButtonV2__spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.LoaderButtonV2 {
    position: relative;
}

.LoaderButtonV2__content,
.LoaderButtonV2__spinner {
    transition: opacity 100ms linear;
}

.LoaderButtonV2__spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0;

    &:after {
        content: '';
        display: block;

        height: 20px;
        width: 20px;

        border-width: 2px;
        border-style: solid;
        border-left-color: transparent;

        border-radius: 50%;

        animation: LoaderButtonV2__spinner linear 600ms infinite;
    }
}

.LoaderButtonV2.tf-button--raised.tf-button--normal {
    @include LoaderButtonV2__spinner-color($tf-button-raised-normal-text-color);
}

.LoaderButtonV2.tf-button--raised.tf-button--brand {
    @include LoaderButtonV2__spinner-color($tf-button-raised-brand-text-color);
}

.LoaderButtonV2.tf-button--raised.tf-button--destructive {
    @include LoaderButtonV2__spinner-color($tf-button-raised-destructive-text-color);
}

.LoaderButtonV2.tf-button--flat.tf-button--normal {
    @include LoaderButtonV2__spinner-color($tf-button-flat-normal-text-color);
}

.LoaderButtonV2.tf-button--flat.tf-button--brand {
    @include LoaderButtonV2__spinner-color($tf-button-flat-brand-text-color);
}

.LoaderButtonV2.tf-button--flat.tf-button--destructive {
    @include LoaderButtonV2__spinner-color($tf-button-flat-destructive-text-color);
}

.LoaderButtonV2.tf-button--outlined.tf-button--normal {
    @include LoaderButtonV2__spinner-color($tf-button-outlined-normal-text-color);
}

.LoaderButtonV2.tf-button--outlined.tf-button--brand {
    @include LoaderButtonV2__spinner-color($tf-button-outlined-brand-text-color);
}

.LoaderButtonV2.tf-button--outlined.tf-button--destructive {
    @include LoaderButtonV2__spinner-color($tf-button-outlined-destructive-text-color);
}

.LoaderButtonV2--loading {
    .LoaderButtonV2__content {
        opacity: 0;
    }

    .LoaderButtonV2__spinner {
        opacity: 1;
    }
}
