@import '../../../assets/styles/config/colors';
@import "../tools.scss";

.MobileAppTabBar {
    display: flex;

    width: 100%;
    height: 0.25em;

    background-color: #f6f6f6;
}

.MobileAppTabBar__tab {
    font-size: 0.11em;

    flex-grow: 1;

    width: 50%;

    border-bottom: solid 1px #ddd;

    line-height: convert-absolute-to-relative(24, 0.11);
    font-weight: 600;
    text-align: center;
    color: #a4a4a4;
}

.MobileAppTabBar__tab--selected {
    border-bottom:
        solid
        convert-absolute-to-relative(2, 0.11)
        $primary-color;

    color: $primary-color;
}
