@import '~@truffls/design-system/scss/settings/colors';

.CandidateConversationTemplatesOverlay {
    padding: 22px 16px;

    display: flex;
    flex-direction: column;
}

.CandidateConversationTemplatesOverlay__title {
    display: block;
    margin-bottom: 12px;

    font-size: 12px;
    color: $color-shades-450;
    line-height: 16px;
    letter-spacing: 0.2px;
}

.CandidateConversationTemplatesOverlay__selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CandidateConversationTemplatesOverlay__selection__dropdown {
    flex: 1;
    min-width: 0;
}

.CandidateConversationTemplatesOverlay__selection__preview-button {
    min-width: 0;

    margin-left: 12px;
    padding: 4px;

    font-size: 10px;
}

.CandidateConversationTemplatesOverlay__preview {
    flex: 1;

    display: flex;
    flex-direction: column;

    margin-top: 29px;

    > textarea {
        width: 100%;
        flex: 1; // fit all height available
        overflow-y: scroll;

        resize: none;

        border: 1px solid $color-shades-200;
        border-radius: 4px;
        padding: 24px;
    }
}
