@import '~@truffls/design-system/scss/settings/colors';

.MessageFormTemplates {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.MessageFormTemplates__label {
    margin-right: 8px;

    font-size: 12px;
    color: $color-shades-450;
}

.MessageFormTemplates__dropdown {
    flex: 1;
    max-width: 176px;
}

.MessageFormTemplates__insert-button {
    min-width: 0;

    margin-left: 4px;
    padding: 4px;

    font-size: 10px;
}
