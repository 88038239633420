.BottomActionBar {
    flex-grow: 0;

    height: 64px;
    background-color: #fff;
    box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.2);
}

.BottomActionBar__flex-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    height: 100%;
}

.BottomActionBar--space-between .BottomActionBar__flex-wrapper {
    justify-content: space-between;
}
