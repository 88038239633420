@import '../../../assets/styles/mixins/respond-to';

.TrialExpiredDialog__image {
    display: block;

    max-width: 100%;

    margin-top: -10px;
}

.TrialExpiredDialog__content {
    border-bottom: 0;
}

@include respond-to('sm') {
    .TrialExpiredDialog__action-cancel {
        margin-right: auto;
    }
}
