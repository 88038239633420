@import "../../assets/styles/mixins/respond-to";

.CandidateListPageFooter {
    margin-top: 45px;
    margin-bottom: 45px;

    padding-left: 16px;
    padding-right: 16px;
}

@include respond-to('sm') {
    .CandidateListPageFooter {
        padding-left: 0;
        padding-right: 0;
    }
}
