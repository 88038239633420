.DocumentTypeIcon {
    width: 100%;
    height: 100%;

    $sizes: (
        xs: 38,
        sm: 48,
        md: 64,
        lg: 96
    );
    @each $key, $size in $sizes {
        &.DocumentTypeIcon--#{$key} {
            width: #{$size}px;
            height: #{$size}px;
        }
    }
}
