@import '~@truffls/design-system/scss/settings/_colors';

.Paper {
    background-color: $color-white;
    border-radius: 2px;
    outline: 0;
}

.Paper--elevation-1 {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.Paper--elevation-2 {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
