@import '~@truffls/design-system/scss/settings/colors';

.CandidateAvatar {
    display: inline-block;
    position: relative;

    height: 100%;
    width: 100%;
}

.CandidateAvatar__new-badge {
    position: absolute;
    bottom: -6px;

    margin-left: 50%;

    transform: translateX(-50%);
}

.CandidateAvatar__messages-badge {
    position: absolute;
    top: -6px;
    right: -4px;
}

.CandidateAvatar--skeleton:before {
    content: '';

    display: inline-block;

    width: 100%;
    height: 100%;

    border-radius: 50%;
    background-color: #ccc;
}
