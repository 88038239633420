.Draggable {
    position: relative
}

.Draggable__content {
    opacity: 1;
}

.Draggable__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    opacity: 0;

    pointer-events: none;

    > * {
        width: 100%;
        height: 100%;
    }
}

.Draggable--dragging {
    .Draggable__content {
        opacity: 0;
    }

    .Draggable__overlay {
        opacity: 1;
    }
}
