@import '~@truffls/design-system/scss/settings/_colors';
@import '../../../../assets/styles/config.scss';

.TrialEndCountdownMenuItem {
    display: block;
    clear: both;
    font-weight: 400;
    line-height: $line-height-base;
    white-space: nowrap;
}

.TrialEndCountdownMenuItem__countdown {
    display: block;

    margin: 4px 8px;
    padding: 8px;

    border-radius: 4px;
    background: $color-truffls-green-500;

    line-height: 20px;
    color: $color-white;
    font-weight: 600;

    text-align: center;
    text-transform: uppercase;
}
