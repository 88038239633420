@use '~@truffls/design-system/scss/settings/_colors' as colors;

@import '../../assets/styles/mixins/respond-to';
@import '../../assets/styles/config';

.CandidateDetailsPageTabs {
    display: flex;
}

.CandidateDetailsPageTabs__tab {
    flex-grow: 1;
    text-align: center;
    line-height: 40px;
    color: colors.$color-shades-400;
    font-size: 15px;
    border-bottom: solid 2px transparent;
    transition: color 0.2s, border-bottom 0.3s;

    &:hover,
    &:active,
    &:focus {
        color: colors.$color-shades-700;
        text-decoration: none;
    }
}

.CandidateDetailsPageTabs__tab--active {
    font-weight: bold;
    color: colors.$color-shades-700;
    border-bottom: solid 2px $brand-primary;
}

.CandidateDetailsPageTabs__tab--profile {
    display: none;
}

@include respond-to('md') {
    .CandidateDetailsPageTabs__tab--profile {
        display: inline;
    }

    .CandidateDetailsPageTabs__tab {
        font-weight: 600;
    }
}
