@import '~@truffls/design-system/scss/settings/_colors';
@import '../variables';

.TutorialDesktopBigArrow {
    position: absolute;
    z-index: $z-index-big-arrows;

    width: 60px;
    height: 60px;

    border: none;
    border-radius: 50%;

    background-color: $color-white;

    box-shadow: 0 20px 14px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    justify-content: center;
    align-items: center;

    > img {
        width: 40px;
        height: 40px;
    }

    &--right {
        right: 40px;
        bottom: 40px;
    }

    &--left {
        left: 40px;
        bottom: 40px;

        > img {
            transform: rotate(180deg);
        }
    }
}

@media (min-width: 1160px) {
    .TutorialDesktopBigArrow {
        width: 100px;
        height: 100px;
    }
}
