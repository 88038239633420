@import '~@truffls/design-system/scss/settings/colors';
@import '../../../assets/styles/mixins/respond-to';

.SearchBarLoadingState .SearchBarLoadingState__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SearchBarLoadingState__content {
    height: 64px;

    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.2px;

    padding: 16px;

    color: $color-shades-450;
}

.SearchBarLoadingState--overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    justify-content: flex-start;

    background-color: rgba($color-white, 0.88);

    .SearchBarLoadingState__content {
        height: 128px;
    }
}

@include respond-to('sm') {
    .SearchBarLoadingState--overlay {
        justify-content: center;

        height: auto;
    }
}
