@import "../../assets/styles/mixins";

.JobListHeader {
    font-size: 16px;
    line-height: 32px;
}

.JobListHeader__columns {
    display: none;
}

@include respond-to('sm') {
    .JobListHeader__toggle {
        display: none;
    }

    .JobListHeader__columns {
        display: flex;

        border-bottom: 1px solid #f4f4f4;
    }

    .JobListHeader__column--state {
        width: 80px;

        flex-grow: 0;
        flex-shrink: 0;
    }

    .JobListHeader__column--title {
        flex-grow: 1;
    }

    .JobListHeader__column--candidates,
    .JobListHeader__column--messages {
        width: 150px;

        flex-grow: 0;
        flex-shrink: 0;

        text-align: center;
    }
}
