@use '~@truffls/design-system/scss/settings/colors' as colors;
@use '../../../assets//styles/mixins/respond-to' as *;

.root {
    display: flex;
    flex-direction: column;

    margin: 0 0 40px;
    padding: 1px 0 0 1px;
}

.tab {
    display: block;

    flex-grow: 1;

    margin-top: -1px;
    margin-left: -1px;

    list-style: none;
}

.tabButton {
    display: block;
    appearance: none;

    width: 100%;

    padding: 7px 11px;

    border: 1px solid colors.$color-shades-900;
    outline: 0;
    background: none;

    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;

    transition: padding 150ms linear, font-size 150ms linear;

    &:focus,
    &:hover {
        background-color: colors.$color-shades-100;
    }
}

.tabActive .tabButton {
    background-color: colors.$color-shades-900;
    color: colors.$color-white;
}

@include respond-to('sm') {
    .root {
        flex-direction: row;
        justify-content: center;
    }

    .tab {
        flex-grow: 0;
    }

    .tabButton {
        padding-left: 15px;
        padding-right: 15px;

        font-size: 16px;
    }
}
