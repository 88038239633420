@import '~@truffls/design-system/scss/settings/_colors';

.CandidateApplicationMessageCallToActionText {
    margin-top: 64px;

    padding: 12px 32px;

    // font-size: 16px;
    text-align: center;
    color: $color-shades-450;
}
