@import '~@truffls/design-system/scss/settings/colors';

.CandidateConversationNavigationBar__title {
    // height: 100%;
    margin-left: 12px;

    align-items: flex-start;

    &__candidate-name {
        margin-top: 0;
        margin-bottom: 3px;

        font: inherit;
        line-height: 1;
    }

    &__job-title {
        color: $color-shades-30;
        font-size: 10px;
        letter-spacing: -0.3px;
        font-weight: 400;
    }
}
