@import '~@truffls/design-system/scss/settings/colors';

.NavigationBar {
    position: relative;

    // We reset the value for parent elements
    flex-grow: 0;

    height: 50px;

    background-color: $color-black;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MobilePageContainer {
    // We reset the value for parent elements
    .NavigationBar {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.NavigationBar--shadow {
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.5);
}

.NavigationBar__left-element,
.NavigationBar__center-element,
.NavigationBar__right-element {
    height: 100%;
}

.NavigationBar__center-element {
    flex: 1;
}

.NavigationBar__left-element,
.NavigationBar__right-element {
    min-width: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 12px;

    > * {
        width: 100%;
        height: 100%;
    }

    .Avatar--bordered {
        color: $color-white;

        .Avatar__image,
        .Avatar__fallback {
            border-color: $color-white;
        }

        .CandidateAvatar__fallback {
            font-size: 18px;
        }
    }
}
