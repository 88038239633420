@use '../../assets/styles/mixins/respond-to' as *;
@use '../../assets/styles/config' as *;

.root {
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
}

@include respond-to('sm') {
    .root {
        width: $container-sm;
    }
}

@include respond-to('md') {
    .root {
        width: $container-md;
    }
}

@include respond-to('lg') {
    .root {
        width: $container-lg;
    }
}
