.ListItemText {
    flex-grow: 1;
    flex-shrink: 1;

    min-width: 0;

    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}
