@import '../../assets/styles/_config';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '../../assets/styles/_mixins';

.header {
    display: none;
}

.positions {
    margin: 0;
    padding: 0;

    list-style: none;
}

.position {
    margin-bottom: 15px;
    padding: 10px;

    border: 1px solid $panel-default-border;

    border-radius: $panel-border-radius;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.positionDescription {
    margin-bottom: 10px;
    margin-right: -10px;
    margin-left: -10px;

    padding: 0 10px 10px;

    border-bottom: 1px solid $panel-default-border;
}

.positionDescriptionMain {
    margin-bottom: 3px;

    font-size: $font-size-base * 1.15;
}

.positionDetail {
    @include clearfix;

    margin-right: -10px;
    margin-left: -10px;
    padding: 2px 10px;
}

.positionDetailLabel,
.positionDetailValue {
    float: left;
    width: 50%;
}

.positionDetailValue {
    text-align: right;
}

.summary {
    padding: 0 11px;
}

.summaryItem {
    @include clearfix;

    padding: 2px 0;
}

.summaryItemLabel,
.summaryItemValue {
    float: left;
    width: 50%;
}

.summaryItemValue {
    text-align: right;
}

.summaryTotal {
    font-size: $font-size-base * 1.05;
    font-weight: bold;
}

@include respond-to('sm') {
    .data {
        display: table;

        width: 100%;

        table-layout: fixed;
        border-collapse: collapse;
    }

    .header {
        display: table-row;
    }

    .headerItem {
        display: table-cell;

        padding-top: 10px;
        padding-bottom: 5px;

        border-bottom: 2px solid $table-border-color;

        vertical-align: bottom;

        font-size: $font-size-base * 1.1;
        font-weight: normal;
        line-height: $line-height-base;
        text-transform: uppercase;
    }

    .headerItemDetail {
        width: 100px;

        text-align: center;
    }

    .positions {
        display: table-row-group;
    }

    .position {
        display: table-row;

        margin: 0;
        padding: 0;

        border: 0;
        border-bottom: 1px solid $table-border-color;
    }

    .positionDescription {
        display: table-cell;

        margin: 0;
        padding: 0;

        border-bottom: 0;
    }

    .positionDescriptionMain {
        font-size: $font-size-base * 1.05;
    }

    .positionDescription:last-child {
        border-bottom-width: 2px;
    }

    .positionDetail {
        display: table-cell;

        width: 100px;

        margin: 0;
        padding: 0;

        text-align: right;

        vertical-align: middle;
    }

    .positionDetailLabel,
    .positionDetailValue {
        float: none;

        width: auto;
    }

    .positionDetailLabel {
        display: none;
    }

    .positionDetailValue {
        display: inline;

        text-align: left;
    }

    .headerItem,
    .positionDescription,
    .positionDetail {
        &:nth-child(even) {
            background-color: lighten($gray-lighter, 4%);
        }
    }

    .summary {
        @include clearfix;

        padding: 0;

        font-size: $font-size-base * 1.1;
        line-height: $line-height-base;
    }

    .summaryItem {
        float: right;
        clear: right;

        width: 300px;

        border-bottom: 1px solid $table-border-color;
    }

    .summaryItemLabel,
    .summaryItemValue {
        display: table-cell;
    }

    .summaryItemLabel {
        width: 200px;

        text-align: right;
    }

    .summaryItemValue {
        width: 100px;
    }

    .summaryItem:last-child {
        border-bottom: 0;
    }

    .headerItem,
    .positionDescription,
    .positionDetail,
    .summaryItemLabel,
    .summaryItemValue {
        padding-top: 5px;
        padding-bottom: 5px;

        padding-right: 8px;
        padding-left: 8px;
    }
}
