@import './MobilePageContainer.variables.scss';

.MobilePageContainer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: $mobile-page-container__z-index;

    display: flex;
    flex-direction: column;

    background-color: #fff;

    > * {
        flex-grow: 1;
        min-height: 1%; // flex item needs height like a block for scrolling
    }
}

.MobilePageContainer__header {
    position: relative;

    width: 100%;
    height: 50px;
    text-align: center;
    transition: all 0.1s;
}

.MobilePageContainer--scrolled .MobilePageContainer__header {
    box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.16);
}

.MobilePageContainer__header-title {
    font-size: 17px;
    font-weight: 600;
    line-height: 50px;
}

.MobilePageContainer__header-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;

    padding: 5px;
    margin: 0;

    font-size: inherit;
    line-height: inherit;

    background: none;
    border: none;
    -webkit-appearance: none;
}

.MobilePageContainer__header-close-icon {
    display: block;
}

.MobilePageContainer__content {
    width: 100%;
    height: 100%;

    overflow-y: auto;

    .MobilePageContainer--has-footer & {
        margin-bottom: 64px;
    }
}
