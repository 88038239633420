@import '~@truffls/design-system/scss/settings/colors';

$action-icon-size: 16px;

.MessageFormToolbar {
    display: flex;
    align-items: center;
}

.MessageFormToolbar__buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.MessageFormToolbar__button {
    width: $action-icon-size;
    height: $action-icon-size;

    & + & {
        margin-left: 12px;
    }

    border: none;
    border-radius: 50%;

    outline: none;

    padding: 0;
    min-width: 0;

    background-color: transparent;
    background-size: $action-icon-size;
    background-repeat: no-repeat;
    background-position: center;

    opacity: 1;
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.8;
    }

    &:focus {
        background-color: $color-shades-100;
    }
}

.MessageFormToolbar__content {
    flex: 1;

    margin-left: 32px;
}
