@import '~@truffls/design-system/scss/settings/colors';

.SearchBarLoadMoreOption {
    margin: 0 8px;
    padding: 8px 8px;

    border-top: 1px solid $color-shades-200;

    text-align: center;
}

.SearchBarLoadMoreOption--highlight {
    background-color: $color-shades-200;
}
