.JobList {
    position: relative;
}

.JobList__list {
    margin: 0;
    padding: 0;

    list-style: none;
}

.JobList__item {
    display: block;

    // Render a border for all children except the last one
    &:not(:last-child) {
        border-bottom: 1px solid #f4f4f4;
    }
}
