@import '../../assets/styles/config/colors';

.CandidateStateItem {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    background: 0;
    border: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.CandidateStateItem__flex-wrapper {
    display: flex;
    align-items: center;
}

.CandidateStateItem__flex-wrapper:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid #4c4c4c;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}

.CandidateStateItem__indicator {
    // position: relative;
    margin: 16px;
    width: 14px;
    height: 14px;
    background-color: #4c4c4c;
    border-radius: 50%;
    border: solid 1px #4c4c4c;
    flex-shrink: 0;
}

.CandidateStateItem__name {
    padding-right: 5px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CandidateStateItem--interesting {
    .CandidateStateItem__indicator {
        background-color: $roman;
        border-color: $roman;
    }
}

.CandidateStateItem--phone-interview {
    .CandidateStateItem__indicator {
        background-color: $malibu;
        border-color: $malibu;
    }
}

.CandidateStateItem--interview {
    .CandidateStateItem__indicator {
        background-color: $sea-nymph;
        border-color: $sea-nymph;
    }
}

.CandidateStateItem--hired {
    .CandidateStateItem__indicator {
        background-color: $casablanca;
        border-color: $casablanca;
    }
}
