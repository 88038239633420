@import '~@truffls/design-system/scss/settings/_colors';
@import '../../Navigation/Navigation.settings';
@import './variables';

.TutorialDesktop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.24);
    z-index: $navigation-bar-z-index + 1;

    // Content is at bottom right corner
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.TutorialDesktop__max-width-container {
    width: 100%;
    max-width: $max-width;

    // Scale animation from bottom right corner
    transform-origin: 100% 100%;

    // Modal gets focused on mount
    &:focus {
        outline: none;
    }
}

.TutorialDesktop__container {
    position: relative;
    overflow: hidden;

    // Maintain aspect ratio
    width: 100%;
    height: 0;
    padding-bottom: 55%;

    color: $color-black;
    background-color: $color-white;

    box-shadow: -6px -8px 80px 0 rgba(0, 0, 0, 0.3), -6px -4px 80px 0 rgba(0, 0, 0, 0.22);

    z-index: $navigation-bar-z-index + 2;

    p {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
    }
}

@media (min-width: $max-width) {
    .TutorialDesktop__container {
        border-top-left-radius: 4px;
    }
}
