@import '~@truffls/design-system/scss/settings/_colors';

.NavigationBarText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;

    font-size: 16px;
    font-weight: 600;
    color: $color-white;
}
