.root {
    overflow-x: hidden;
}

.image {
    margin-top: 24px;
}

.buttonContainer {
    padding-top: 30px;
    padding-bottom: 30px;
}
