@import '~@truffls/design-system/scss/settings/_colors';

.MessageItemDate {
    margin-top: 24px;
    margin-bottom: 16px;
}

.MessageItemDate__body {
    font-size: 12px;
    line-height: 16px;

    color: $color-shades-450;

    letter-spacing: 0.2px;
    text-transform: uppercase;
    text-align: center;
}
