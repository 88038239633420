@import '../../../assets/styles/mixins/respond-to';
@import '~@truffls/design-system/scss/settings/colors';

.RequestDocumentsViewOtherInputs {
    visibility: hidden; // make it non focusable by keyboard but animation still works

    max-height: 0;

    overflow: hidden;
    transition: max-height 0.3s;

    &--showing {
        visibility: visible;

        max-height: 200px;

        margin-top: 24px;
    }
}

.RequestDocumentsViewOtherInputs__remove-button-container {
    flex-grow: 0;
    flex-shrink: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 56px;

    padding: 4px 12px;
}

.RequestDocumentsViewOtherInputs__remove-button {
    width: 32px;
    height: 32px;
    background-color: #fff;
    background-image: url('../../../assets/images/ic-delete-outlined.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    opacity: 1;
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.8;
    }

    &:focus {
        outline: none;
        background-color: #eaeaea;
    }
}

.RequestDocumentsViewOtherInputs__input {
    display: flex;
    align-items: flex-start;
}

.RequestDocumentsViewOtherInputs__input__field {
    flex-grow: 1;
}

.RequestDocumentsViewOtherInputs__input__label {
    font-size: 10px;
    font-weight: normal;
    color: $color-shades-450;
    margin-bottom: 0;
}

.RequestDocumentsViewOtherInputs__title {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: $color-brand;

    margin-bottom: 12px;
}

.RequestDocumentsViewOtherInputs__add-button {
    background-color: transparent;
    border: none;

    padding: 0;
    padding-bottom: 10px;
    margin-top: 12px;

    transition: opacity 0.2s;

    font-size: 12px;
    color: $color-brand;
    text-decoration: underline;

    &:hover {
        opacity: 0.8;
    }

    &:focus,
    &:active {
        outline: none;
    }

    &:before {
        content: '';
        display: inline-block;
        margin-right: 8px;
        width: 16px;
        height: 16px;
        background-image: url('../../../assets/images/ic-add.svg');
        background-size: contain;
        vertical-align: middle;
    }
}

@include respond-to('md') {
    .RequestDocumentsViewOtherInputs {
        max-width: 350px;
    }
}
