@import '../../assets/styles/_config.scss';
@import '../../assets/styles/_mixins.scss';

.Headline {
    margin-top: (30/14) * 1em;
    margin-bottom: (30/14) * 1em;

    // XXX Set to make it possible to use relative units
    font-size: 14px;
}

.Headline__title,
.Headline__subtitle {
    font-weight: normal;
}

.Headline__title {
    margin-top: 0;
    margin-bottom: 0;

    font-size: (18/14) * 1em;
    font-weight: bold;
}

.Headline__subtitle {
    margin-top: (5/14) * 1em;
    margin-bottom: 0;

    font-size: (16/14) * 1em;

    color: $gray;
}

.Headline--center {
    text-align: center;
}

@include respond-to('sm') {
    .Headline__title {
        font-size: (30/14) * 1em;
    }

    .Headline__subtitle {
        font-size: (20/14) * 1em;
    }
}
