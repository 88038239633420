.ResumeAboutMe {
}

.ResumeAboutMe__summary {
    margin-bottom: 12px;
}

.ResumeAboutMe__item {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 8px;
}

.ResumeAboutMe__item:last-child {
    margin-bottom: 0;
}

.ResumeAboutMe__icon {
    display: block;

    height: 20px;
    width: 20px;

    fill: currentColor;
}

.ResumeAboutMe__value {
    margin-left: 8px;

    color: inherit;
}
