$size: 24px;

.NavigationBarCloseAction {
    width: $size;
    height: $size;

    line-height: $size;

    padding: 6px;

    display: flex;
    align-items: center;

    // Reset button
    margin: 0;
    background: none;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
}

.NavigationBarCloseAction__icon {
    display: block;

    width: 100%;
    height: 100%;
}
