@import '~@truffls/design-system/scss/settings/_colors';

.TutorialDesktopContentGtc {
    margin-top: 24px;
}

.TutorialDesktopContentGtc__paragraph {
    margin-bottom: 24px;
}

.TutorialDesktopContentGtc__conditions-checkbox {
    .tf-checkbox__label {
        font-size: 12px;
        color: $color-shades-450 !important;
    }
    .tf-checkbox__faux {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}
