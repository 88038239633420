@import '~@truffls/design-system/scss/settings/colors.scss';

.CandidatesJobList {
    display: block;
    margin: 0;
    padding: 0;
}

.CandidatesJobList__item {
    display: block;
    list-style: none;

    padding-top: 4px;
    padding-bottom: 4px;
}

.CandidatesJobList__job-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CandidatesJobList__job-link,
.CandidatesJobList__job-location,
.CandidatesJobList__link-note {
    height: 18px;
}

.CandidatesJobList__job-link {
    flex-grow: 0;
    flex-shrink: 0;

    letter-spacing: -0.2px;

    color: $color-black;
    text-decoration: underline;
}

.CandidatesJobList__job-location,
.CandidatesJobList__link-note {
    flex-grow: 1;
    flex-shrink: 1;

    margin-left: 8px;

    font-size: 12px;
    line-height: 16px;
    color: $color-shades-450;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.CandidatesJobList__link-note {
    display: none;

    flex-direction: row;
    align-items: center;
}

.CandidatesJobList__link-note-icon {
    display: block;

    height: 16px;
    width: 16px;

    margin-right: 4px;

    fill: currentColor;
}

.CandidatesJobList__job-link:hover + .CandidatesJobList__job-location,
.CandidatesJobList__job-link:hover ~ .CandidatesJobList__job-location {
    display: none;
}

.CandidatesJobList__job-link:hover + .CandidatesJobList__link-note,
.CandidatesJobList__job-link:hover ~ .CandidatesJobList__link-note {
    display: flex;
}
