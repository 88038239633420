.SearchBarCloseButton {
    appearance: none;

    height: 24px;
    width: 24px;

    padding: 0;

    border: none;
    background-color: transparent;

    color: inherit;
}

.SearchBarCloseButton__icon {
    display: block;

    height: 100%;
    width: 100%;

    fill: currentColor;
}
