
@keyframes rotate {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}

.LoadingWheel {
    display: block;

    height: 75px;
    width: 75px;

    background-image: url(./images/ic_loading-wheel.svg);

    animation-name: rotate;
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
