@import '~@truffls/design-system/scss/settings/_colors';

.PortraitPicture {
    position: relative;

    &,
    .PortraitPicture__frame,
    .PortraitPicture__initials {
        height: 100%;
        width: 100%;
    }

    .PortraitPicture__frame {
        margin: 0;
        padding: 0;

        border-radius: 50%;
        border: 1px solid $color-shades-200;
        background-color: $color-shades-200;

        overflow: hidden;
    }

    .PortraitPicture__image {
        display: block;

        max-height: 100%;
        max-width: 100%;
    }

    .PortraitPicture__initials {
        display: flex;
        flex-direction: column;
        justify-content: center;

        text-align: center;

        color: $color-white;
    }

    &--xs {
        height: 30px;
        width: 30px;

        .PortraitPicture__initials {
            font-size: 12px;
        }
    }

    &--md,
    &--lg {
        .PortraitPicture__initials {
            font-size: 24px;
        }
    }

    &--md {
        height: 60px;
        width: 60px;
    }

    &--lg {
        height: 100px;
        width: 100px;
    }
}
