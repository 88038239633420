@import "../../../assets/styles/config";

.CandidateResumeItem {
    display: block;

    padding-top: 10px;
    padding-bottom: 10px;

    border-top: 1px solid $gray-lighter;

    .CandidateResumeItem__label {
        margin-bottom: 3px;
    }

    &:first-child {
        padding-top: 0;

        border-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
}
