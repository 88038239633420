@import '~@truffls/design-system/scss/settings/colors';

.MessageFormTextarea__content {
    margin-bottom: 4px;
}

.MessageFormTextarea__input {
    display: block;

    width: 100%;
    max-height: 250px;
    resize: none;

    padding: 8px 16px;
    line-height: 18px;

    border-radius: 4px;

    background-color: rgba($color-shades-200, 0.24);

    border: none;
    outline: none;
    box-shadow: none;
}

.MessageFormTextarea--legacy-theme .MessageFormTextarea__input {
    background-color: $color-white;
}
