@import '../../assets/styles/mixins/respond-to';
@import '../../assets/styles/config';

.CandidateRatingButtons {
    display: flex;
    align-items: center;
    height: 24px;
}

.CandidateRatingButtons__item {
    text-align: center;

    margin-right: 32px;

    &:last-child {
        margin-right: 0;
    }
}

.CandidateRatingButtons__item-input-label {
    display: block;

    width: 24px;
    max-width: none;
    height: 24px;

    margin: 0;

    border: solid 1px #4c4c4c;
    border-radius: 50%;

    font-size: 10px;
    text-align: center;
    line-height: 22px;
    font-weight: normal;
    cursor: pointer;
    transition: all 0.3s;
}

.CandidateRatingItem__item-input:checked + .CandidateRatingButtons__item-input-label {
    color: $color-white;

    border-color: $color-brand;
    background-color: $color-brand;
}

@include respond-to('sm') {
    .CandidateRatingButtons__item {
        margin-right: 20px;
    }
}
