@import './containers/Navigation/Navigation.settings.scss';

#app {
    display: flex;
    flex-direction: column;

    position: relative;

    // height: auto !important;
    // See: https://connect.microsoft.com/IE/feedback/details/802625/min-height-and-flexbox-flex-direction-column-dont-work-together-in-ie-10-11-preview
    height: 100%;

    min-height: 100%;

    .app__content,
    .app__footer {
        flex-shrink: 0;
    }

    .app__content {
        flex-grow: 1;
    }

    .app__content {
        position: relative;

        // This fixes the broken detection of the height for children which are using relatvie sizes.
        // This problem occures in Safari and older Chrome version.
        height: 0;
    }
}

.no-scroll {
    position: relative;
    overflow: hidden !important;
}

// Hack for using style in components injected in the index.js
@import 'components/BrowserUnsupported/BrowserUnsupported.style.scss';
@import 'components/Spinner/style.scss';
