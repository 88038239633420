.JobFormRequirementsListFormFields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    max-width: 100%;

    overflow: hidden;

    margin: 0 -6px 24px;

    > * {
        overflow: hidden;
    }
}

.JobFormRequirementsListFormFields__item-container {
    margin: 0 6px 6px;
}
