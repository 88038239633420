@import "../variables";

.TutorialMobileVideoAnimationWrapper {
    position: absolute;
    top: 0;
    left: 0;

    width: 1%;
    height: 1%;
    overflow: visible;
    // final translate values as animation fallback
    transform: translate(10000%, 10000%);

    z-index: $z-index-video;
}
.TutorialMobile--step {
    .TutorialMobileVideoAnimationWrapper {
        // final translate values as animation fallback
        transform: translate(0%, 0%);
    }
}

.TutorialMobileVideo {
    display: block;
    width: 250px;
    height: 191px;

    box-shadow: 0 -20px 30px 0 rgba(0, 0, 0, 0.1);
    background-color: white;

    // final translate values as animation fallback
    transform: translate(-100%, -110%);

    > * {
        width: 100%;
    }
}
.TutorialMobile--step {
    .TutorialMobileVideo {
        box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
        // final translate values as animation fallback
        transform: translate(0%, 16px);
    }
}
