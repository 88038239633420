@import '~@truffls/design-system/scss/settings/_colors';

@import '../../../assets/styles/mixins';

.JobFormLocationFormFieldset__job-region-warning-icon {
    fill: $color-pale-cerulean-500;
}

@include respond-to('md') {
    .JobFormLocationFormFieldset__location-field-group {
        display: flex;
        flex-direction: row;

        margin-bottom: 8px;
    }

    .JobFormLocationFormFieldset__location-field-input {
        flex-basis: 64%;

        padding-right: 6px;
    }

    .JobFormLocationFormFieldset__location-field-job-region {
        flex-basis: 36%;

        padding-left: 6px;
    }

    .JobFormLocationFormFieldset__remote-possible-field {
        margin-bottom: 16px;
    }
}
