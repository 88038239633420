.ScaleTransition--enter-active,
.ScaleTransition--exit-active {
    transition-property: opacity, transform;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.ScaleTransition--enter {
    opacity: 0;
    transform: scale(1.1);
}

.ScaleTransition--enter-active {
    opacity: 1;
    transform: scale(1);
}

.ScaleTransition--exit {
    opacity: 1;
    transform: scale(1);
}

.ScaleTransition--exit-active {
    opacity: 0;
    transform: scale(1.1);
}
