@import '~@truffls/design-system/scss/settings/_colors';
@import './MessageItemWrapper.variables';

.MessageItemWrapper {
    display: flex;
    align-items: flex-start;

    margin-top: 8px;
    margin-bottom: 4px;
}

.MessageItemWrapper__sender-portrait {
    flex-shrink: 0;

    width: $message-portrait-size;
    height: $message-portrait-size;

    margin-right: $message-portrait-margin;
}

.MessageItemWrapper__content {
    border-radius: 4px;

    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);

    word-wrap: break-word; // fallback for non supporting browsers
    overflow-wrap: break-word;
    min-width: 1%; // flex items need a min-width for word-wrap to be applied

    a[href],
    a[href]:hover,
    a[href]:focus {
        color: inherit;
        text-decoration: underline;
    }
}

/* VARIATIONS */
.MessageItemWrapper--not-from-me {
    flex-direction: row;

    padding-left: $message-indentation;
    padding-right: $message-min-breath-space;

    .MessageItemWrapper__content {
        background-color: $color-white;
        color: $color-black;
    }
}

.MessageItemWrapper--from-me {
    flex-direction: row-reverse;

    padding-right: $message-indentation;
    padding-left: $message-min-breath-space + $message-portrait-size + $message-portrait-margin;

    .MessageItemWrapper__content {
        background-color: $color-brand;
        color: $color-white;
    }
}
