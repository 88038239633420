@import '../../assets/styles/config';
@import '../../assets/styles/mixins';

@import '../Navigation/Navigation.settings.scss';

.CheckoutPage {
    position: relative;

    min-height: 100%;
    // height: 100%;

    padding-bottom: 28px;
}

@include respond-to('sm') {
    .CheckoutPage {
        padding-bottom: 28px;
    }
}
