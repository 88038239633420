.FadeInTransition--enter-active,
.FadeInTransition--exit-active {
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.FadeInTransition--enter {
    opacity: 0;
}

.FadeInTransition--enter-active {
    opacity: 1;
}

.FadeInTransition--exit {
    opacity: 1;
}

.FadeInTransition--exit-active {
    opacity: 0;
}
