.LayerStyles {
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    overflow: visible;
}

.LayerStyles__visible {
    overflow: visible;
}
