@import '~@truffls/design-system/scss/settings/_colors';
@import '../../assets/styles/mixins/respond-to';

.JobDetailsMeta__list {
    display: block;

    margin: 0;
    padding: 0;
}

.JobDetailsMeta__item {
    display: flex;
    flex-direction: row;
    align-items: center;

    list-style: none;

    padding: 16px 0;

    border-bottom: 1px solid $color-shades-200;
}

.JobDetailsMeta__item-icon,
.JobDetailsMeta__item-content {
    height: 24px;

    margin-left: 16px;
}

.JobDetailsMeta__item-icon {
    display: block;

    width: 24px;

    fill: $color-shades-400;
}

.JobDetailsMeta__item-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.JobDetailsMeta__item-label,
.JobDetailsMeta__item-value {
    display: block;
}

.JobDetailsMeta__item-value {
    margin-left: 8px;

    font-weight: 600;
}

.JobDetailsMeta__information {
    margin-bottom: 0;

    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 12px;

    font-style: italic;
}

@include respond-to('md') {
    .JobDetailsMeta__item-icon,
    .JobDetailsMeta__item-content {
        margin-left: 24px;
    }
}
