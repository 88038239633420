@use "../../assets/styles/mixins/respond-to" as *;

.root {
    padding-top: 0;
}

.headline,
.actions,
.statistics,
.createAccount {
    margin-left: 16px;
    margin-right: 16px;
}

.fallbackTitle {
    font-style: italic;
}

.actions {
    margin-top: 24px;
}

.statistics {
    margin-top: 48px;
}

.createAccount {
    margin-top: 24px;
}

@include respond-to('sm') {
    .headline,
    .actions,
    .statistics,
    .createAccount {
        margin-left: 0;
        margin-right: 0;
    }
}
