@import '~@truffls/design-system/scss/settings/colors';

$base-height-textarea: 34px;

.MessageForm {
    position: relative;
    padding: 4px 12px;
}

.MessageForm--legacy-theme {
    background-color: $color-shades-30;
}

.MessageForm__main {
    width: 100%;
    min-height: $base-height-textarea;

    margin-bottom: 4px;

    display: flex;
    align-items: flex-end;
}

.MessageForm__main__input {
    flex: 1;
}

.MessageForm__main__submit {
    width: 48px;
    height: $base-height-textarea;

    margin-left: 12px;

    border-radius: 2px;

    // overwrite .tf-button
    padding: 0;
    min-width: 0;
    box-shadow: none;

    &:before {
        content: '';

        display: inline-block;

        width: 24px;
        height: 100%;

        background-image: url('../../assets/images/ic_paper-plane.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
    }
}

.MessageForm__toolbar {
    width: 100%;

    padding-top: 4px;
    margin-bottom: 4px;

    // Icons
    &__buttons__ {
        &request-documents {
            background-image: url('../../assets/images/ic_documents-outlined.svg');
        }

        &documents {
            background-image: url('../../assets/images/ic_paperclip-2-filled.svg');
        }

        &templates {
            background-image: url('../../assets/images/ic-email-template-outlined.svg');
        }
    }
}
