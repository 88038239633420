@import '~@truffls/design-system/scss/settings/_colors';

.ButtonAsLink {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    padding: 0;

    background: none;

    color: $color-brand;
    text-decoration: none;

    border: none;

    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        color: darken($color-brand, 15%);
        text-decoration: underline;
    }
}
