@use '../../assets/styles/mixins/_respond-to' as *;

.doNotShowAgain {
    display: none;
    position: absolute;

    left: 30px;
    bottom: 12px;
}

@include respond-to('sm') {
    .doNotShowAgain {
        display: block;
    }
}
