@import '../../assets/styles/_config';

.JobDetailsStats__items {
    display: flex;
    // flex-wrap: wrap;
    align-items: stretch;

    margin: -5px -5px;
    padding: 0;
}

.JobDetailsStats__item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    list-style: none;

    max-width: 100px;

    padding-top: 15px;
    padding-bottom: 15px;

    padding-left: 5px;
    padding-right: 4px;

    border-right: 1px solid lighten($gray-light, 27);

    &:last-child {
        padding-right: 5px;
        border-right: 0;
    }
}


.JobDetailsStats__item-label,
.JobDetailsStats__item-value {
    display: block;

    text-align: center;
}

.JobDetailsStats__item-label {
    color: $gray-light;

    font-size: 10px;
}

.JobDetailsStats__item-value {
    font-size: 22px;
    font-weight: bold;
}
