@import "../config/breakpoints.scss";

// See: http://www.sitepoint.com/managing-responsive-breakpoints-sass/

@mixin respond-to ($breakpoint, $sidebar: false) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint);

    // If the key exists in the map
    @if $value != null {

        @if $sidebar {
            $max-width-without-sidebar: ($sidebar-breakpoint - 1px);
            $min-width-with-sidebar: ($value + $sidebar-width);

            @if $max-width-without-sidebar > ($min-width-with-sidebar - 1px) {
                $max-width-without-sidebar: ($min-width-with-sidebar - 1px);
            }

            @media
                (min-width: $value) and (max-width: $max-width-without-sidebar),
                (min-width: ($value + $sidebar-width))
            {
                @content;
            }
        }
        @else {
            @media (min-width: $value) {
                @content;
            }
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Please make sure it is defined in `$breakpoints` map.";
    }
}
