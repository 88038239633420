@import '../../assets/styles/mixins/respond-to';

.Details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Details__primary-content,
.Details__secondary-content {
    display: block;

    line-height: 1.2;
}

.Details__primary-content {
    font-size: 18px;
    font-weight: 600;
}

.Details__primary-content.Details__primary-content--truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

// .Details__secondary-content {
//     color: $gray;
// }

.Details__primary-content + .Details__secondary-content {
    margin-top: 0.3em;
}

.Details__secondary-content + .Details__primary-content {
    margin-top: 0.15em;
}



.Details__primary-content--skeleton,
.Details__secondary-content--skeleton {
    background-color: #ccc;
    border-radius: 250em;
}


.Details__primary-content--skeleton {
    width: 75%;
    height: 15px;
    max-width: 420px;
    margin-bottom: 6px;
}

.Details__secondary-content--skeleton {
    width: 50%;
    height: 10px;
    max-width: 250px;
}


@include respond-to('sm') {
    .Details__primary-content {
        font-size: 24px;
    }

    .Details__primary-content--skeleton {
        height: 20px;
        margin-top: 2px;
        margin-bottom: 5px;
    }

    .Details__secondary-content--skeleton {
        height: 12px;
    }
}
