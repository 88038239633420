@import '~@truffls/design-system/scss/settings/_colors';

.MessageListScroll {
    position: absolute;

    width: 100%;
    height: 100%;

    padding-bottom: 50px;

    background-color: $color-shades-30;

    overflow-x: hidden;
    overflow-y: scroll;
}

.MessageListScroll--legacy-theme {
    background-color: $color-white;
}
