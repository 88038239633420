.JobFormTasksCreateFormField {
    display: flex;
    flex-direction: row;

    margin-bottom: 24px;
}

.JobFormTasksCreateFormField__input {
    flex-grow: 1;
    flex-shrink: 1;
}

.JobFormTasksCreateFormField__input-control {
    resize: none;
}

.JobFormTasksCreateFormField__submit-button {
    margin-left: 12px;
}
