@import '../../assets/styles/config';
@import '../../assets/styles/mixins';

// TODO: Feels wrong that a stateless component has to import something from a container component.
@import '../../containers/Navigation/Navigation.settings.scss';

.root {
    padding-top: 10px;
}

.hasInfobar {
    padding-top: 0;
}

@include respond-to('sm') {
    .root {
        padding-top: 20px;
    }

    .hasInfobar {
        padding-top: $navigation-bar-height + 20px;
    }
}
