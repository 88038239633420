.productSubtitle {
    min-height: 44px;
}

.productNotes {
    margin: 0;
    padding: 32px 16px;
    text-align: center;
}

.productNote {
    margin-bottom: 0;
    font-size: 16px;
}
