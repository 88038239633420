.SignupFormStepHeadline {
    margin: 16px 0 12px;

    font-size: 26px;
    line-height: 1.25em;
    font-style: italic;
}

.SignupFormStepHeadline--light {
    color: #9c9c9c;
}
