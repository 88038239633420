@import '~@truffls/design-system/scss/settings/colors';

.SearchBarEmptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 64px;

    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.2px;

    padding: 16px;

    color: $color-shades-450;
}
