@import '../../assets/styles/mixins/respond-to';

.DialogPageContainerV2__body {
    background-image: url(./images/background.jpg);
    background-position: center;
    background-size: cover;
}

.DialogPageContainerV2__container {
    max-width: 640px;

    margin: 48px auto;

    background-color: white;

    &:before {
        content: '';

        display: block;
        height: 4px;
    }

   @include respond-to ('md') {
       margin-top: 110px;
   }
}

.DialogPageContainerV2__content {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 24px;
    padding-bottom: 16px;
}
