.ResetPasswordPage__small-container {
    margin: 0 auto;
    max-width: 380px;
}

.ResetPasswordPage__separator {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid #eaeaea;
}

.ResetPasswordPage__instructions {
    text-align: center;
}
