@import '../../assets/styles/mixins/_respond-to';

.CheckoutConfirmAdvertisementForm__gtc-label {
    display: block;

    padding-left: 1.2em;
}

.CheckoutConfirmAdvertisementForm__gtc-link {
    &,
    &:hover,
    &:focus {
        color: inherit;
        text-decoration: underline;
    }
}

.CheckoutConfirmAdvertisementForm__product-overview {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 16px;
    width: 340px;
}

@include respond-to('md') {
    .CheckoutConfirmAdvertisementForm__summaries {
        display: flex;
        flex-direction: row;
    }

    .CheckoutConfirmAdvertisementForm__product-overview {
        display: block;
    }
}
