@import "../../assets/styles/mixins/respond-to";

.CandidateListHeader {
    display: flex;

    font-size: 16px;
    line-height: 32px;
}

.CandidateListHeader__columns {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
}

.CandidateListHeader__column,
.CandidateListHeader__toggle {
    padding-top: 8px;
    padding-bottom: 8px;
}

.CandidateListHeader__column {
    padding-left: 8px;
    padding-right: 8px;
}

.CandidateListHeader__column-control {
    display: none;
}

.CandidateListHeader__column--rating {
    flex-grow: 0;
    flex-shrink: 0;

    width: 70px;

    text-align: center;
}

.CandidateListHeader__column--created {
    display: none;
    width: 110px;
    text-align: center;
}

.CandidateListHeader__column--candidate {
    flex-grow: 1;
    flex-shrink: 1;
}

.CandidateListHeader__column--state {
    flex-grow: 0;
    flex-shrink: 0;

    display: none;
}

.CandidateListHeader__toggle {
    flex-grow: 0;
    flex-shrink: 0;

    margin-right: 16px;
}

@include respond-to('sm') {
    .CandidateListHeader {
        padding-top: 36px;

        line-height: 28px;
    }

    .CandidateListHeader__columns {
        border-bottom: 1px solid #f4f4f4;
    }

    .CandidateListHeader__column {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .CandidateListHeader__column-label {
        display: none;
    }

    .CandidateListHeader__column-control {
        display: inline;
    }

    .CandidateListHeader__column--rating {
        width: 80px;
    }

    .CandidateListHeader__column--state {
        display: flex;

        height: 200%;
        width: 455px;

        margin-top: -36px;

        padding-top: 0px;
        padding-bottom: 0px;

        padding-left: 0;
        padding-right: 0;
    }

    .CandidateListHeader__column--state .CandidateListHeader__column-control {
        align-self: flex-end;

        width: 34px;

        margin-left: -34px;

        padding-top: 4px;
        padding-bottom: 4px;
    }

    .CandidateListHeader__toggle {
        display: none;
    }
}


@include respond-to('md') {
    .CandidateListHeader__column--created {
        display: inline-block;
    }
}
