@import '../../assets/styles/config/_colors';
@import '../../assets/styles/mixins/_respond-to';

.SessionExpiredPage__dialog {
    padding: 32px 16px;

    max-width: 330px;

    text-align: center;

    border-top: solid 8px $color-brand;
}

.SessionExpiredPage__title {
    font-size: 34px;
    font-style: italic;
}

.SessionExpiredPage__description {
    margin-top: 16px;
    margin-bottom: 24px;
}

.SessionExpiredPage__action-signup {
    display: none;
}

@include respond-to ('sm') {
    .SessionExpiredPage__dialog {
        padding: 32px 16px 60px;

        max-width: 800px;
    }

    .SessionExpiredPage__title {
        font-size: 48px;
    }

    .SessionExpiredPage__description {
        margin-top: 32px;
        margin-bottom: 32px;

        font-size: 18px;
    }

    .SessionExpiredPage__action-signup {
        display: inline;

        margin-right: 8px;
    }
}
