@use '../../assets/styles/mixins/respond-to' as *;

.root {
    background-color: #ffffff;
}

.inline {
    display: none;
}

.fixed {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 500;

    padding: 16px 0;

    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);

    .action {
        width: 100%;
    }
}

.fixed + .spacer {
    height: 66px;
}

@include respond-to('xs') {
    .fixed {
        .action {
            width: auto;
        }
    }
}

@include respond-to('sm') {
    .inline {
        display: block;

        margin-top: 12px;
        padding: 12px 15px;
    }

    .fixed {
        display: none;
    }
}
