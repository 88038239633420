@import '../../assets/styles/config/colors';

.ResetPasswordConfirmPage__small-container {
    margin: 0 auto;
    max-width: 380px;
}

.ResetPasswordConfirmPage__seperator {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid #eaeaea;
}

.ResetPasswordConfirmPage__instructions {
    text-align: center;
}
