@import '~@truffls/design-system/scss/settings/_colors';

$file-icon-size: 40px;

.MessageItemAttachment {
    padding: 4px;

    &:hover {
        cursor: pointer;
    }

    a {
        color: inherit;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.MessageItemAttachment__content {
    display: flex;
    align-items: center;
}

.MessageItemAttachment__file-icon {
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: $file-icon-size;
    height: $file-icon-size;

    padding: 4px;

    border-radius: 4px;

    margin-right: 8px;

    > img {
        max-width: 100%;
        max-height: 100%;
    }
}

.MessageItemAttachment__file-info {
    flex: 1;

    min-width: 1%; // flex items need a min-width for word-wrap to be applied

    margin-right: 6px;
}

.MessageItemAttachment__file-info-name,
.MessageItemAttachment__file-info-meta {
    display: block;
}

.MessageItemAttachment__file-info-name {
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.25px;
}

.MessageItemAttachment__file-info-meta {
    font-size: 10px;
    letter-spacing: -0.34px;
}

/* VARIATIONS */
.MessageItemWrapper--not-from-me {
    .MessageItemAttachment__file-info-meta {
        color: $color-shades-450;
    }

    .MessageItemAttachment__file-icon {
        background-color: rgba(204, 204, 204, 0.24);
    }
}

.MessageItemWrapper--from-me {
    .MessageItemAttachment__file-info-meta {
        color: $color-shades-100;
    }

    .MessageItemAttachment__file-icon {
        background-color: rgba(255, 255, 255, 0.24);
    }
}
