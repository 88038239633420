@import "../../assets/styles/_config.scss";

.DialogActionBar {
    display: flex;
    justify-content: space-between;

    padding-top: 20px;
    padding-bottom: 20px;

    border-top: 1px solid $gray-lighter;
}

.DialogActionBar--center {
    justify-content: center;
}

.DialogActionBar--right {
    justify-content: flex-end;
}

.DialogActionBar__action {
    text-align: center;
}
