@import '~@truffls/design-system/scss/settings/_colors';
@import '../../../assets/styles/mixins';

.LogoNav {
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-left: 16px;
}

.LogoNav__logo-container {
    height: 38px;

    margin-right: 20px;

    transition: all 0.09s;
}

.LogoNav__logo-link {
    display: block;
}

.LogoNav__logo-image {
    display: block;
    height: 38px;
}

.LogoNav__logo-container:active {
    transform: scale(0.95);
}

.LogoNav__back-button {
    width: 20px;
    margin-right: 10px;

    font-size: 16px;
    font-weight: bold;
    color: $color-shades-30;

    // Reset button
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    outline: none;
    padding: 0;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.LogoNav__back-button-arrow {
    margin-top: -4px;
    margin-right: 6px;
    width: 20px;
}

.LogoNav__back-button-text {
    display: none;
}

@include respond-to('sm') {
    .LogoNav {
        flex-direction: row;
        justify-content: flex-start;
        margin-left: 36px;
    }

    .LogoNav__logo-container {
    }

    .LogoNav__back-button {
        margin-right: 0;
        width: auto;
        overflow: auto;
    }

    .LogoNav__back-button-text {
        display: inline;
    }
}
