.Avatar,
.Avatar__image,
.Avatar__fallback {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}

.Avatar {
    display: inline-block;
}

.Avatar__image {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Avatar__image-faux {
    display: block;
    height: 100%;
    width: 100%;
    opacity: 0;
}

.Avatar__fallback {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Avatar--highlighted {
    .Avatar__image,
    .Avatar__fallback {
        border: solid 2px #4c4c4c;
    }
}

.Avatar--bordered {
    .Avatar__image,
    .Avatar__fallback {
        border: solid 1px #4c4c4c;
    }
}

.Avatar--squared {
    border-radius: 0;

    .Avatar__image,
    .Avatar__fallback {
        border-radius: 0;
    }
}
