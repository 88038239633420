@import '~@truffls/design-system/scss/settings/colors';

.CandidateConversation {
    display: flex;
    flex-direction: column;

    height: 100%;
    overflow: hidden;

    outline: none; // not focus outline for file dropzone
}

.CandidateConversation__drag-overlay {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba($color-white, 0.92);
    color: $color-black;
}

.CandidateConversation__drag-overlay__illustration {
    width: 200px;
    margin-bottom: 32px;
}

.CandidateConversation__drag-overlay__title {
    font-size: 34px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: -0.4px;
}

.CandidateConversation__message-list {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;

    display: flex;
    position: relative;

    height: 100%;

    -webkit-overflow-scrolling: touch;
}

.CandidateConversation__message-form {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
}
