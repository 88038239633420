.BadgeIcon {
    display: inline-block;

    position: relative;

    padding: 12px 12px;
}

.BadgeIcon__primary-badge,
.BadgeIcon__secondary-badge {
    position: absolute;
}

.BadgeIcon__primary-badge {
    top: 0;
    right: 0;

    z-index: 1;
}

.BadgeIcon__secondary-badge {
    bottom: 0;
    left: 0;
    right: 0;

    text-align: center;
}

.BadgeIcon--faded:after {
    content: '';

    display: block;
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: #fff;
    opacity: 0.5;

    z-index: 0;
}
