@import '../MobilePageContainer/MobilePageContainer.variables.scss';

$modal__z-index: $mobile-page-container__z-index + 2;

.PartialOverlay {
    position: fixed;
    // top value provided from props
    left: 0;
    bottom: 0;
    right: 0;

    background-color: #fff;

    z-index: $modal__z-index;

    box-shadow: 0 -1px 16px 2px rgba(0, 0, 0, 0.24);
    border-radius: 16px 16px 0 0;

    overflow: hidden;

    > * {
        height: 100%;
    }

    // Modal auto focuses on mount
    &:focus {
        outline: none;
    }
}

.PartialOverlay__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: $modal__z-index - 1;
}
