@import '../../assets/styles/mixins/respond-to';

.CandidateNotesItem__desktop-view {
    display: none;

    width: 100%;
}

.CandidateNotesItem__desktop-view__title {
    margin: 10px 0 4px;

    font-size: 16px;
    font-weight: 600;
}

.CandidateNotesItem__desktop-view__textarea {
    min-height: 88px;
    max-height: 88px;
    resize: none;
}

.CandidateNotesItem__desktop-view__button {
    text-align: right;
}

.CandidateNotesItem__mobile-bar {
    position: fixed;

    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1;

    padding: 0 16px;

    width: 100%;
    height: 48px;

    background-color: #fff;

    text-align: center;
    line-height: 48px;

    box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.2);

    cursor: pointer;
}

.CandidateNotesItem__mobile-bar:after {
    content: '';

    float: right;
    margin-top: 18px;

    width: 0;
    height: 0;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #4c4c4c;
}

@include respond-to('md') {
    .CandidateNotesItem__desktop-view {
        display: block;
    }

    .CandidateNotesItem__mobile-bar {
        display: none;
    }
}

@include respond-to('lg') {
    .CandidateNotesItem__desktop-view__textarea {
        max-height: 106px;
    }
}
