.JobFormHiringBudgetFormFields__fields,
.JobFormHiringBudgetFormFields__fields-budget {
    display: flex;
    flex-direction: row;
}

.JobFormHiringBudgetFormFields__fields-budget,
.JobFormHiringBudgetFormFields__field-date {
    flex-grow: 1;
    flex-shrink: 1;
}

.JobFormHiringBudgetFormFields__fields-budget {
    max-width: 276px;
}

.JobFormHiringBudgetFormFields__fields-budget-seperator {
    flex-grow: 0;
    flex-shrink: 0;

    padding: 0 4px;

    line-height: 56px;
}

.JobFormHiringBudgetFormFields__field-budget-lower,
.JobFormHiringBudgetFormFields__field-budget-higher {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
}

.JobFormHiringBudgetFormFields__field-scale {
    max-width: 200px;

    margin-left: 12px;
}

.JobFormHiringBudgetFormFields__currency-faux-icon {
    text-align: center;
}
