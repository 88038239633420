$color-brand: #28A54B;

$primary-color: #28A54B;
$primary-color-inset: darken($primary-color, 20%);



$color-white: #ffffff;

$color-red: #cc6666;

$color-green: #28A54B;

$black-marlin: #343446;
$roman: #d86c65;
$sea-nymph: #86b4a4;
$malibu: #6fb4d9;
$casablanca: #f4b755;

$colors-flat: (
    'turquoise': '#1ABC9C',
    'green-sea': #16A085,

    'emerald': #2ECC71,
    'nephritis': #27AE60,

    'peter-river': #3498DB,
    'belize-hole': #2980B9,

    'amethyst': #9B59B6,
    'wistria': #8E44AD,

    'wet-asphalt': #34495E,
    'midnight-blue': #2C3E50,

    'sun-flower': #F1C40F,
    'orange': #F39C12,

    'carrot': #E67E22,
    'pumpkin': #D35400,

    'alizarin': #E74C3C,
    'pomegranate': #C0392B,

    'clouds': #ECF0F1,
    'silver': #BDC3C7,

    'concrete': #95A5A6,
    'asbestos': #7F8C8D
);

$colors-flat-light: (
    'turquoise',
    'emerald',
    'peter-river',
    'amethyst',
    'wet-asphalt',
    'sun-flower',
    'carrot',
    'alizarin',
    'clouds',
    'concrete',
);

$colors-flat-dark: (
    'green-sea',
    'nephritis',
    'belize-hole',
    'wistria',
    'midnight-blue',
    'orange',
    'pumpkin',
    'pomegranate',
    'silver',
    'asbestos'
);

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-bg:                         #333333;

// Inverted navbar links
$navbar-inverse-link-color:                 $color-white;
$navbar-inverse-link-hover-color:           $color-white;
$navbar-inverse-link-active-color:          $color-brand;
$navbar-inverse-link-active-bg:             $navbar-inverse-bg;

