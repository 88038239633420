.JobDetailsCreateAccount {
    text-align: center;
}

.JobDetailsCreateAccount__description {
    display: block;

    max-width: 500px;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

    font-size: 18px;
}
