@import "../../assets/styles/config";
@import "../../assets/styles/mixins";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";


.CheckoutProgressBar__step {
    position: relative;

    padding: 0;
}

.CheckoutProgressBar__step-label {
    height: 20px;

    margin-top: 6px;
    margin-bottom: 5px;

    line-height: 20px;

    vertical-align: bottom;

    color: $gray;
}

.CheckoutProgressBar__step-dot {
    display: block;

    position: absolute;

    width: 30px;
    height: 30px;

    background: $gray-lighter;

    top: 49px;
    left: 50%;

    margin-top: -15px;
    margin-left: -15px;

    border-radius: 50%;
}

.CheckoutProgressBar__step-progress {
    position: relative;

    border-radius: 0;

    height: 8px;

    margin: 20px 0;

    box-shadow: none;

    background: $gray-lighter;
}

.CheckoutProgressBar__step-progress-bar {
    width: 0px;

    box-shadow: none;

    background: $primary-color;

    transition: none;
}


.CheckoutProgressBar__step--active,
.CheckoutProgressBar__step--complete {
    .CheckoutProgressBar__step-label {
        color: $gray-dark;
    }

    .CheckoutProgressBar__step-dot {
        background: $primary-color;
    }

    .CheckoutProgressBar__step-dot:after {
        content: ' ';
        display: block;

        position: absolute;
        top: 8px;
        left: 8px;

        width: 14px;
        height: 14px;

        background: $primary-color-inset;

        border-radius: 50%;
    }
}

.CheckoutProgressBar__step--active {
    .CheckoutProgressBar__step-label {
        height: 26px;

        margin-top: 0;

        line-height: 26px;
        font-weight: bold;
        font-size: $font-size-large;
    }

    .CheckoutProgressBar__step-progress > .progress-bar {
        width: 50%;
    }
}

.CheckoutProgressBar__step--active:first-child {
    .CheckoutProgressBar__step-progress > .progress-bar {
        width: 0;
    }
}

.CheckoutProgressBar__step--active:last-child,
.CheckoutProgressBar__step--complete {
    .CheckoutProgressBar__step-progress > .progress-bar {
        width: 100%;
    }
}

.CheckoutProgressBar__step:first-child  {
    .CheckoutProgressBar__step-progress {
        left: 50%;

        width: 50%;
    }
}

.CheckoutProgressBar__step:last-child {
    .CheckoutProgressBar__step-progress {
        width: 50%;
    }
}
