// See: https://github.com/jovey-zheng/loader/blob/master/src%2Fball-fade.css

$loading-overlay__indicator--color: #4a4a4a;
$loading-overlay__indicator--smallSize: 10px;
$loading-overlay__indicator--mediumSize: 25px;
$loading-overlay__indicator--largeSize: 40px;

@mixin loading-overlay__inner--mixin($size, $space) {
    position: absolute;

    top: 50%;
    left: 50%;

    height: $size;
    width: ($size * 3) + ($space * 2);

    // reset
    margin: 0;

    margin-top: -($size / 2);
    margin-left: -((($size * 3) + ($space * 2)) / 2);

    padding-left: $size + $space;
}

@mixin loading-overlay__indicator--mixin($size, $space) {
    @keyframes loading-overlay__indicator-animation
    {
        0%
        {
            background-color: rgba($loading-overlay__indicator--color, 1);
        }
        100%
        {
            background-color: rgba($loading-overlay__indicator--color, 0);
        }
    }
    
    position: relative;

    width: $size;
    height: $size;

    animation: 1.2s loading-overlay__indicator-animation infinite cubic-bezier(.78, .14, .15, .86) .2s;

    border-radius: 100%;
    background-color: rgba($loading-overlay__indicator--color, .0);

    &:before,
    &:after {
        position: absolute;

        width: $size;
        height: $size;

        content: '';
        animation: 1.2s loading-overlay__indicator-animation infinite cubic-bezier(.78, .14, .15, .86);

        border-radius: 100%;
        background-color: rgba($loading-overlay__indicator--color, .0);
    }

    &:before {
        left: -($size + $space);
    }

    &:after {
        right: -($size + $space);

        animation-delay: .4s;
    }
}

@mixin innerAndIndicatorOfSize($size) {
    .loading-overlay__inner {
        @include loading-overlay__inner--mixin($size, $loading-overlay__indicator--smallSize);
    }
    .loading-overlay__indicator {
        @include loading-overlay__indicator--mixin($size, $loading-overlay__indicator--smallSize);
    }
}

.loading-overlay {
    position: absolute;
    z-index: 100;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    pointer-events: none;

    &--small {
        @include innerAndIndicatorOfSize($loading-overlay__indicator--smallSize);
    }

    &--medium {
        @include innerAndIndicatorOfSize($loading-overlay__indicator--mediumSize);
    }

    &--large {
        @include innerAndIndicatorOfSize($loading-overlay__indicator--largeSize);
    }
}

.loading-overlay--background {
    background: rgba(204, 204, 204, 0.7);
}

.loading-overlay--full-screen {
    position: fixed;
}


