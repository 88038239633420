@import '~@truffls/design-system/scss/settings/_colors';

$dot-size: 8px;
$dot-gap: 8px;

:export {
    dotSize: $dot-size;
    dotGap: $dot-gap;
}

.StepsDots {
    position: relative;
    line-height: $dot-size;
}

.StepsDots__button {
    width: $dot-size;
    height: $dot-size;

    // reset button
    padding: 0;
    border: none;

    border-radius: 50%;
    background-color: $color-shades-100;

    + .StepsDots__button {
        margin-left: $dot-gap;
    }
}

.StepsDots__active-indicator {
    position: absolute;
    top: 0;
    // left comes from style attribute

    display: block;
    width: $dot-size;
    height: $dot-size;

    border-radius: 50%;
    background-color: $color-brand;

    transition: left 0.2s linear;
}
