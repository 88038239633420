@import '../../assets/styles/mixins/respond-to';

.ActionNotifications {
    position: fixed;
    z-index: 1000;

    bottom: 0;
    right: 0;

    width: 100%;

    padding: 8px;

    pointer-events: none;
}

@include respond-to('xs') {
    .ActionNotifications {
        text-align: right;

        padding: 24px;
    }

    .ActionNotifications__animation-container {
        display: inline-block;

        width: 568px;

        text-align: right;
    }
}
