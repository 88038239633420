@import '~@truffls/design-system/scss/settings/_colors';
@import '~@truffls/design-system/scss/tools/reset-button';
@import '../../assets/styles/mixins/respond-to';

$dropdown-menu-item-height: 22px;
$caret-size: 4px;

.TemplatesDropdown {
    width: 100%;
}

.TemplatesDropdown__button {
    @include reset-button;

    position: relative;

    width: 100%;
    height: 20px;

    padding-left: 4px;
    padding-right: 26px; // 26px = 4px same as left + 22px for the caret

    font-weight: 600;

    background-color: $color-white;

    border-radius: 10px;
    border: 1px solid $color-shades-200;

    &:focus {
        outline: none;
        background-color: $color-shades-30;
    }

    // open/closed caret
    &:after {
        content: '';
        position: absolute;
        top: 7px;
        right: 10px;

        width: 0;
        height: 0;
        border-left: $caret-size solid transparent;
        border-right: $caret-size solid transparent;

        border-top: $caret-size solid $color-shades-800; // Caret pointing down
    }

    &--open {
        &:after {
            border-top: none;
            border-bottom: $caret-size solid $color-shades-800; // Caret pointing up
        }
    }
}

.TemplatesDropdown__menu {
    padding: 2px 4px;

    width: 100%;

    background-color: $color-white;
    box-shadow: 0 1px 3px 0.5px rgba(0, 0, 0, 0.16);

    border-radius: 4px;
    border: 1px solid $color-shades-200;

    &--scrollable {
        height: $dropdown-menu-item-height * 10 + 15px; // display 10 items + cognite zone
        overflow-y: scroll;
    }
}

.TemplatesDropdown__menu__item {
    @include reset-button;

    display: block;

    width: 100%;
    height: $dropdown-menu-item-height;

    padding: 0 4px;

    & + & {
        border-top: 1px solid $color-shades-100;
    }

    &:hover,
    &:focus {
        background-color: rgba($color-truffls-green-100, 0.56);
    }
}

@include respond-to('sm') {
    .TemplatesDropdown__menu {
        width: auto;
        max-width: 300px;
    }
}
