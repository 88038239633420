@import '~@truffls/design-system/scss/settings/_colors';
@import './Navigation.settings.scss';

.root,
.navigation {
    height: $navigation-bar-height;
}

.navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $navigation-bar-z-index;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 48px;

    background-color: $color-black;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
