@import '../../assets/styles/mixins/respond-to';

.SignupPage {
    height: 100%;
    min-height: 100%;
}

.SignupPage__content {
    flex-grow: 1;
    flex-shrink: 1;

    padding-bottom: 8px;
}

@include respond-to('md') {
    .SignupPage__content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-bottom: 56px;
    }
}
