@import '~@truffls/design-system/scss/settings/colors';

.SignupPageFooter {
    padding: 0 20px;

    color: $color-shades-450;
}

.SignupPageFooter__menu {
    display: block;
    margin: 0;
    padding: 16px 0;

    border-top: solid 1px $color-shades-200;

    list-style: none;

    text-align: center;
}

.SignupPageFooter__menu-item {
    display: inline-block;
}

.SignupPageFooter__menu-item + .SignupPageFooter__menu-item:before {
    content: '•';

    display: inline-block;

    margin-left: 8px;
    margin-right: 8px;
}

.SignupPageFooter__link {
    line-height: 1.3;

    &,
    &:hover,
    &:focus {
        color: inherit;
    }
}
