@use '~@truffls/design-system/scss/settings/_colors' as colors;

@use '../../assets/styles/mixins/respond-to' as *;

.root {
    padding: 0;
    text-align: center;
}

.form,
.progressBar,
.progressUrl,
.error {
    margin: 0 auto;
}

.form {
    text-align: left;
}

.label-hint {
    display: block;
    color: #777;
}

.progressContainer {
    padding-top: 8px;
}

.progressBar {
    height: 40px;
}

.progressUrl {
    margin-top: 4px;
    padding: 0 12px;

    overflow: hidden;

    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1px;
    font-weight: 300;
    color: colors.$color-shades-450;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.action {
    margin-top: 40px;
}

.error {
    margin-top: 20px;
    color: colors.$color-harvard-crimson-400;
    font-size: 12px;
}

@include respond-to('sm') {
    .form,
    .progressBar,
    .progressUrl,
    .error {
        max-width: 484px;
    }
}
