.ContactPage {
    padding: 0 16px;
}

.ContactPage__title {
    margin: 50px 0;
}

.ContactPage__description {
    margin: 16px auto;
    text-align: center;
    font-size: 14px;
    max-width: 600px;
}

.ContactPage__secondary-title {
    margin: 10px 0;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.ContactPage__contact-infos {
    margin: 0 auto;
    max-width: 380px;
}

.ContactPage__contact-info-item {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
}

.ContactPage__contact-info-item__text {
    padding: 8px;
}

.ContactPage__contact-info-item__icon {
    display: inline-block;
    margin-right: 10px;
    width: 40px;
    height: 40px;
}

.ContactPage__contact-info-item__link {
    color: inherit;
}

.ContactPage__map-container {
    position: relative;
    margin: 40px 0 40px;
    width: 100%;
    height: 300px;
    overflow: hidden;
}
