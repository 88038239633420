@import '~@truffls/design-system/scss/settings/_colors';
@import '../../Navigation/Navigation.settings';
@import './variables';

.TutorialMobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.24);
    z-index: $navigation-bar-z-index + 1;
}

.TutorialMobile__container {
    position: relative;

    height: 100%;
    overflow: hidden;

    padding: 32px 24px;

    color: $color-black;
    background-color: $color-white;

    // Scale animation from bottom right corner
    transform-origin: 100% 100%;

    // Modal gets focused on mount
    &:focus {
        outline: none;
    }

    p {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
    }
}

.TutorialMobile__steps-dots {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}
