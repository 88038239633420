@import '../../assets/styles/mixins/respond-to';

.JobCreateSwitcher {
    margin: 0 -15px 36px;
    padding-bottom: 10px;
    border-bottom: solid 1px #c9c9c9;
}

.JobCreateSwitcher__container {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 564px;
}

.JobCreateSwitcher__button {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 14px;
    border: solid 1px #c9c9c9;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;

    &,
    &:hover,
    &:active,
    &:focus {
        color: #4c4c4c;
        text-decoration: none;
    }
}

.JobCreateSwitcher__button--active,
.JobCreateSwitcher__button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.32);
}

.JobCreateSwitcher__button-icon {
    width: 40px;
    height: 40px;
}


@include respond-to('sm') {
    .JobCreateSwitcher {
        padding: 16px 0;
        margin: 72px 0 36px;
    }

    .JobCreateSwitcher__container {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 484px;
    }

    .JobCreateSwitcher__button {
        width: 120px;
        height: 120px;
        font-size: 20px;
    }

    .JobCreateSwitcher__button-icon {
        width: 50px;
        height: 50px;
    }
}
