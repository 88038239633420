.ExpiringCandidateMessagingView__form {
    display: flex;
    flex-direction: column;
}

.ExpiringCandidateMessagingView__textarea {
    resize: none;
}

.ExpiringCandidateMessagingView__modal-header {
    text-align: center;
}

.ExpiringCandidateMessagingView__modal-title {
    font-weight: 600;
}

.ExpiringCandidateMessagingView__modal-body {
    display: flex;
    position: relative;
    padding: 0;
}

.ExpiringCandidateMessagingView__app-preview {
    margin: 0;
    width: 50%;
    height: 300px;
    overflow: hidden;
    background-image: url('../../assets/images/notification_preview_background.png');
}

.ExpiringCandidateMessagingView__app-preview__title {
    padding: 16px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
}

.ExpiringCandidateMessagingView__app-preview__phone {
    margin: 0 auto;
    width: 230px;
}

.ExpiringCandidateMessagingView__modal-explanation{
    padding: 10px;
    width: 50%;
}

.ExpiringCandidateMessagingView__modal-footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ExpiringCandidateMessagingView__content {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    flex-grow: 1;
    flex-shrink: 1;

    overflow-y: auto;
}

.ExpiringCandidateMessagingView__heading,
.ExpiringCandidateMessagingView__cta {
    margin-bottom: 5px;
    font-weight: 600;
}

.ExpiringCandidateMessagingView__heading {
    font-size: 16px;
}

.ExpiringCandidateMessagingView__textarea-label {
    font-weight: 600;
    font-size: 14px;
}

.ExpiringCandidateMessagingView__overlay-container .ExpiringCandidateMessagingView__heading {
    display: block;
    text-align: center;
    font-size: 16px;
    margin-bottom: 25px;
}
