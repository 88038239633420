$separation: 12px;

.TagGroup {
    margin-bottom: -$separation;

    > * {
        margin-right: $separation;
        margin-bottom: $separation;
    }

    > *:last-child {
        margin-right: 0;
    }
}
