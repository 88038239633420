@import '~@truffls/design-system/scss/settings/_colors.scss';

@import '../../../assets/styles/mixins/_respond-to.scss';

.CandidateDetailsRow {
    flex-shrink: 0;

    min-height: 24px;

    margin-bottom: 20px;

    font-size: 14px;
}

.CandidateDetailsRow__label {
    margin-bottom: 4px;

    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
}

.CandidateDetailsRow__value {
    line-height: 18px;
    letter-spacing: 0.25px;
}

.CandidateDetailsRow--one-line {
    display: flex;
    align-items: center;

    .CandidateDetailsRow__label {
        flex-grow: 0;
        flex-shrink: 0;

        width: 100px;

        margin-bottom: 0px;
        margin-right: 20px;
    }

    .CandidateDetailsRow__value {
        flex-grow: 1;
        flex-shrink: 1;

        margin-bottom: 0;
    }
}

.CandidateDetailsRow--border {
    padding-top: 20px;
    padding-bottom: 20px;

    border-top: 1px solid $color-shades-200;
    border-bottom: 1px solid $color-shades-200;
}

@include respond-to('md') {
    .CandidateDetailsRow {
        padding-right: 10px;
    }

    .CandidateDetailsRow:not(.CandidateDetailsRow--stacked) {
        display: flex;
        align-items: center;

        .CandidateDetailsRow__label {
            flex-grow: 0;
            flex-shrink: 0;

            width: 100px;

            margin-bottom: 0px;
            margin-right: 20px;
        }

        .CandidateDetailsRow__value {
            flex-grow: 1;
            flex-shrink: 1;

            margin-bottom: 0;
        }
    }

    .CandidateDetailsRow--sticky-label {
        .CandidateDetailsRow__label {
            align-self: flex-start;
        }
    }
}
