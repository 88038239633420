@import '../../assets/styles/mixins/respond-to';

.CopyJobListItem {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.CopyJobListItem__informations {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 15px;
    overflow: hidden;
}

.CopyJobListItem__state {
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    flex-shrink: 0;
    flex-grow: 0;
}

.CopyJobListItem__title {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.CopyJobListItem__action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 10px 16px;
    flex-grow: 0;
    flex-shrink: 0;
}

.CopyJobListItem__button {
    width: 100%;
}

.CopyJobListItem__button-wrapper {
    display: block;
    position: relative;
}

.CopyJobListItem--skeleton {
    .CopyJobListItem__state:before,
    .CopyJobListItem__details-primary:before {
        content: '\00a0';
        display: inline-block;
        background-color: #cccccc;
        border-radius: 250em;
        vertical-align: middle;
    }

    .CopyJobListItem__state:before {
        width: 40px;
        height: 0.8em;
    }

    .CopyJobListItem__details-primary:before {
        width: 70%;
        height: 0.8em;
        max-width: 420px;
        margin-top: -3px;
    }

    .CopyJobListItem__button-wrapper:before {
        content: '\00a0';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #cccccc;
        border-radius: 250em;
    }

    .CopyJobListItem__button {
        opacity: 0;
        pointer-events: none;
    }
}

@include respond-to('sm') {
    .CopyJobListItem {
        flex-direction: row;
    }

    .CopyJobListItem__state {
        width: 80px;
        height: 80px;
        line-height: 80px;
    }

    .CopyJobListItem__action {
        flex-direction: row;
        align-items: center;
        padding: 0;
        width: 250px;
        text-align: center;
    }

    .CopyJobListItem__button {
        width: auto;
    }

    .CopyJobListItem--skeleton {
        .CopyJobListItem__details-primary:before {
            margin-top: -5px;
        }
    }
}
