.CandidateNotesMobilePage__content {
    flex-grow: 1;
    flex-shrink: 1;

    padding: 16px;

    overflow-y: auto;
}

.CandidateNotesMobilePage__input {
    min-height: 100px;
    max-height: 200px;
}
