@import "../variables";

.TutorialDesktopVideoAnimationWrapper {
    position: absolute;
    top: 0;
    left: 0;

    width: 40%;
    height: 10%;
    overflow: visible;
    // final translate values as animation fallback
    transform: translate(238%, 120%);

    z-index: $z-index-video;
}
.TutorialDesktop--step {
    .TutorialDesktopVideoAnimationWrapper {
        // final translate values as animation fallback
        transform: translate(17%, 120%);
    }
}

.TutorialDesktopVideo {
    display: block;
    width: 112.5%;

    // final translate values as animation fallback
    transform: translate(-100%, 0%);

    > div {
        width: 100%;
        height: 0;
        padding-bottom: 76%;
        box-shadow: 0 50px 50px 0 rgba(0, 0, 0, 0.1);
        background-color: white;

        > * {
            display: block;
            width: 100%;
        }
    }
}

.TutorialDesktop--step {
    .TutorialDesktopVideo {
        width: 100%;
        // final translate values as animation fallback
        transform: translate(0%, 0%);
    }
}
