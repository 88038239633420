.FilterControlMenu {
    display: none;
}

.FilterControlMenu--open {
    display: block;

    position: absolute;
    right: -130px;
    z-index: 100;

    margin-bottom: 20px;

    width: 160px;

    font-size: 14px;
    line-height: (20px / 14px);
}

.FilterControlMenu__triangle:after,
.FilterControlMenu__menu {
    background: #ffffff;
}

.FilterControlMenu__triangle {
    position: relative;
    bottom: -1px;

    padding-left: 15px;

    overflow: hidden;
}

.FilterControlMenu__triangle:after {
    content: '';

    display: block;

    height: 15px;
    width: 15px;

    transform: rotate(45deg);
    transform-origin: bottom left;

    box-shadow: 2.5px 2.5px 10px rgba(#000000, 0.24);
}

.FilterControlMenu__menu {
    // border: 1px solid #cccccc;
    border-radius: 5px;

    box-shadow: 0 5px 10px rgba(#000000, 0.24);
}

.FilterControlMenu__menu-header,
.FilterControlMenu__menu-footer {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;

    text-align: center;
}

.FilterControlMenu__menu-header {
    font-size: 17px;
    font-weight: 600;
}

.FilterControlMenu__menu-content {
    text-align: left;
}

.FilterControlMenu__menu-option {
    display: block;

    margin-bottom: 0;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;

    white-space: nowrap;

    cursor: pointer;

    input[type=radio]:checked + &,
    input[type=radio]:checked ~ &,
    input[type=checkbox]:checked + &,
    input[type=checkbox]:checked ~ &,
    &:active,
    &:focus {
        font-weight: bold;
        background: #f7f7f7;
    }

    &:hover {
        background: #fafafa;
    }
}

.FilterControlMenu__menu-option-control {
    margin-right: 0;
}

.FilterControlMenu__menu-option-label {
    padding-left: 16px;
}
