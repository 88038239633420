@import '~@truffls/design-system/scss/settings/_colors';
@import '../../assets/styles/mixins/respond-to';

.DecorationLine {
    display: block;
    width: 40px;
    height: 4px;

    border-radius: 5px;

    background-color: $color-brand;
}

@include respond-to('md') {
    .DecorationLine {
        width: 60px;
        height: 8px;
    }
}
