.root {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

// We have to override the behavior from the design system so that we can use the gap to control the spacing between the buttons on any axis.
.root :global(.tf-button) + :global(.tf-button) {
    margin-left: 0;
}
