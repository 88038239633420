.TutorialMobileContentText {
    position: relative;
}

.TutorialMobileContentText__title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.4px;

    margin-top: 0;
    margin-bottom: 15px;
}

.TutorialMobileContentText__paragraph {
    margin-bottom: 0;
}
