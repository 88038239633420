@import '../../../assets/styles/mixins/respond-to';

.SignupPageContent__content {
    flex-grow: 1;

    max-width: 400px;

    margin: 0 auto;
    padding: 0 32px;
}

@include respond-to('md') {
    .SignupPageContent__content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 50%;
        min-width: 400px;
        max-width: 580px;
    }
}
