@import "../../assets/styles/config";
@import "../../assets/styles/mixins";

.PackageChooser {
    max-width: 500px;

    margin: 0 auto;
    padding: 1px 0 0;
}

.PackageChooser__item {
    display: block;
    list-style: none;
}

@include respond-to('md') {
    .PackageChooser {
        min-height: 140px;
    }
}
