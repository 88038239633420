@import '../../assets/styles/config/colors';

$states: 'locked' 'interesting' 'phone-interview' 'interview' 'hired';

$stateColors: (
    'locked': $black-marlin,
    'interesting': $roman,
    'phone-interview': $malibu,
    'interview': $sea-nymph,
    'hired': $casablanca
);

.CandidateStateProgress {
    display: block;
}

.CandidateStateProgress__bar {
    display: flex;
}

.CandidateStateProgress__radio {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

.CandidateStateProgress__cell {
    flex-grow: 1;
    flex-shrink: 1;
}

.CandidateStateProgress__state-name {
    padding: 10px 2px;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
}

.CandidateStateProgress__state-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    height: 40px;
    text-align: center;
}

.CandidateStateProgress__state-indicator:before,
.CandidateStateProgress__state-indicator:after {
    content: '';
    display: block;
    height: 1px;
    background-color: #c9c9c9;
    flex-grow: 1;
    flex-shrink: 1;
    z-index: -1;
    transition: background-color 0.5s;
}

.CandidateStateProgress__state-indicator-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: solid 1px #c9c9c9;
    border-radius: 50%;
    cursor: pointer;
    transition: width 0.5s, height 0.5s;

    &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        transition: width 0.2s, height 0.2s, background-color 0.4s;
    }
}

@each $state in $states {
    .CandidateStateProgress__bar--#{$state} {
        .CandidateStateProgress__state-indicator:before,
        .CandidateStateProgress__state-indicator:after {
            background-color: map-get($stateColors, $state);
        }

        .CandidateStateProgress__cell--#{$state} ~ .CandidateStateProgress__cell {
            .CandidateStateProgress__state-indicator:before,
            .CandidateStateProgress__state-indicator:after {
                background-color: #c9c9c9;
            }

            .CandidateStateProgress__state-indicator-circle:before {
                background-color: #fff;
            }
        }
    }
}

.CandidateStateProgress__cell--locked {
    .CandidateStateProgress__state-indicator-circle {
        display: none;
    }

    .CandidateStateProgress__state-indicator:before {
        opacity: 0;
    }
}

.CandidateStateProgress__cell--hired {
    .CandidateStateProgress__state-indicator:after {
        opacity: 0;
    }
}

.CandidateStateProgress__radio:checked + .CandidateStateProgress__cell ~ .CandidateStateProgress__cell {
    .CandidateStateProgress__state-indicator-circle {
        width: 16px;
        height: 16px;
        background-color: #fff;
        border: solid 1px #c9c9c9;
    }
}

.CandidateStateProgress__radio:checked + .CandidateStateProgress__cell {
    .CandidateStateProgress__state-name {
        font-weight: 600;
    }

    .CandidateStateProgress__state-indicator-circle {
        width: 16px;
        height: 16px;
    }

    @each $state in $states {
        &.CandidateStateProgress__cell--#{$state} .CandidateStateProgress__state-indicator-circle {
            border: solid 1px map-get($stateColors, $state);

            &:before {
                width: 10px;
                height: 10px;
                background-color: map-get($stateColors, $state);
            }
        }
    }
}

.CandidateStateProgress__radio:checked + .CandidateStateProgress__cell .CandidateStateProgress__state-indicator:after {
    background-color: #c9c9c9;
}

.CandidateStateProgress__bar--locked {
    .CandidateStateProgress__state-indicator-circle {
        display: inline-flex;
    }

    .CandidateStateProgress__cell.CandidateStateProgress__cell--interesting {
        .CandidateStateProgress__state-indicator .CandidateStateProgress__state-indicator-circle {
            width: 16px;
            height: 16px;
            border: solid 1px $roman;
            animation: pulse 2s infinite;

            &:before {
                background-color: #fff;
            }
        }

        & ~ .CandidateStateProgress__cell {
            .CandidateStateProgress__state-indicator .CandidateStateProgress__state-indicator-circle {
                width: 0;
                height: 0;
                border: solid 0 #fff;
            }
        }
    }
}

.CandidateStateProgress__bar--hired {
    .CandidateStateProgress__state-indicator-circle {
        display: none;
    }

    .CandidateStateProgress__cell.CandidateStateProgress__cell--hired {
        .CandidateStateProgress__state-indicator .CandidateStateProgress__state-indicator-circle {
            display: inline-flex;
        }
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 transparentize($roman, 0.5);
    }
    70% {
        box-shadow: 0 0 0 8px transparent;
    }
    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

.CandidateStateProgress__arrow-container {
    position: relative;
    margin-top: -12px;
    height: 20px;
    pointer-events: none;
    transition: opacity 0.3s;

    &.CandidateStateProgress__arrow-container--hidden {
        opacity: 0;
    }
}

.CandidateStateProgress__arrow {
    position: absolute;
    height: 20px;
}

.CandidateStateProgress__arrow-head {
    position: absolute;
    margin-top: -4px;
    transform: rotateZ(-30deg);

    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: solid 3px transparent;
        border-bottom: solid 3px transparent;
        border-left: solid 6px #c9c9c9;
    }
}

.CandidateStateProgress__description {
    font-size: 12px;
    font-style: italic;
    text-align: center;
}
