@import "../tools.scss";

.MobileAppStatusBar {
    display: flex;
    flex-shrink: 0;
    position: relative;
    padding: 0 0.03em;
    width: 100%;
    height: 0.12em;
    color: #000;
    background-color: #f6f6f6;
}

.MobileAppStatusBar__left-section,
.MobileAppStatusBar__right-section {
    display: flex;
    align-items: center;
    width: 35%;
}

.MobileAppStatusBar__right-section {
    justify-content: flex-end;
}

.MobileAppStatusBar__clock {
    font-size: 0.08em;

    flex-grow: 1;
    text-align: center;
}

.MobileAppStatusBar__element-signal {
    height: 0.04em;
}

.MobileAppStatusBar__element-carrier {
    font-size: 0.07em;

    margin-left: convert-absolute-to-relative(3, 0.07);
}

.MobileAppStatusBar__element-wifi {
    height: 0.06em;

    margin-left: 0.03em;
}

.MobileAppStatusBar__element-bluetooth {
    height: 0.08em;
}

.MobileAppStatusBar__element-battery {
    height: 0.06em;

    margin-left: 0.03em;
}
