@import '../../assets/styles/mixins/respond-to';

.ListEmpty {
    padding: 32px;

    color: #767676;
    font-size: 15px;
    text-align: center;
    font-style: italic;
}

@include respond-to('sm') {
    .ListEmpty {
        font-size: 17px;
    }
}
